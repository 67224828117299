<template>
<div>
    <div class="card">
        <div class="nav_box">
            <div class="nav1 navbg_blue">土地信息</div>
            <div class="nav2 navbg_blue2">房价信息</div>
            <div class="nav3 navbg_blue2">材料价格信息</div>
            <div class="nav4 navbg_gray2">工程造价指标</div>
        </div>
        <div class="tab_box">
            <div class="input_box">
                <p>日期：</p>
                <el-date-picker v-model="trekkingDate" type="month" placeholder="选择日期" value-format="yyyy-MM"></el-date-picker>
                <p>规格型号：</p>
                <el-input class="search-content" size="small" v-model="trekkingModel" placeholder="点击输入规格型号" ></el-input>
                <p>材料名称：</p>
                <el-input class="search-content" size="small" v-model="trekkingName" placeholder="点击输入材料名称" ></el-input>
            
                <el-button class="search_btn" type="primary" size="small" @click="searchTrekkingData(true)">查询</el-button>
                <el-button class="search_btn" size="small" @click="reset">重置</el-button>
                
            </div>
        </div>
        <div class="main_box">
            <materialTable :materialData="materialData" :trekkingPage="trekkingPage"></materialTable>
            <div class="page-box">
                <el-pagination
                    @size-change="trekkingHandleSizeChange"
                    @current-change="trekkingHandleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 20, 50, 100, 1000]"
                    :total="trekkingtotal"
                    :current-page="trekkingPageNum">
                </el-pagination>
            </div>
        </div>
    </div>
    <div class="next_box">
        <div class="pop_btn" @click="toNext('house')">上一步</div>
        <div class="next_btn" @click="toNext('project')">下一步</div>
    </div>
</div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import materialTable from '@/views/industryAnalysis/materialTable.vue'
export default {
    name:'housePrice',
    components:{
        materialTable:materialTable,
    },
    data() {
        return {
            relationId: '',
            materialData: [],
            trekkingName: '',
            trekkingModel: '',
            trekkingDate: '',
            trekkingPageNum: 1,
            trekkingPageSize: 10,
            trekkingPage: {
                trekkingPageNum: 1,
                trekkingPageSize: 10,
            },
            trekkingtotal: 0,
        }
    },
    created() {
   
    },
 
    mounted() {
        this.searchTrekkingData(false);
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取材料数据
        searchTrekkingData(flag){
            if(flag){
                this.trekkingPageNum = 1;
                this.getReport(2,'material','searchBtn');
            }
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getTrekkingPrice',
                 
                data:{
                    userId:getLocalStore('userinfo').id,
                    date:this.trekkingDate,
                    model:this.trekkingModel,
                    name:this.trekkingName,
                    pageNum:this.trekkingPageNum,
                    pageSize:this.trekkingPageSize,
                    userSource: getLocalStore("userinfo").userSource,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                }
            }).then((res) => {
               this.materialData = res.data.data.records;
               this.trekkingtotal = res.data.data.total;
            })
        },
        //材料页面操作
        trekkingHandleSizeChange(val){
            this.trekkingPageSize = val;
            this.trekkingPageNum = 1;
            this.trekkingPage.trekkingPageSize = val;
            this.trekkingPage.trekkingPageNum = 1;
            this.searchTrekkingData(false);
        },
        trekkingHandleCurrentChange(val){
            this.getReport(2,'material',val);
            this.trekkingPageNum = val;
            this.trekkingPage.trekkingPageNum = val;
            this.searchTrekkingData(false);
        },
        reset(){
            this.trekkingDate = '';
            this.trekkingModel = '';
            this.trekkingName = '';
        },
        //下一步
        toNext(tag){
            this.axios
            .request({
                loading: true,
                method: "post",
                url: "/dms/calculate/trekkingPriceComplete",
                data: {
                    date:'',
                    model:'',
                    name:'',
                    pageNum:this.trekkingPageNum,
                    pageSize:this.trekkingPageSize,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                    userId: this.userId,
                    userSource: this.userSource,
                },
            })
            .then((res) => {
                this.$emit("listenNext",tag);
            });
            
        },
   
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.module-container{
    height: 100%;
}
.card{
    width: 100%;
    height: 582px;
    background-color: #fff;
    .nav_box{
        width: 100%;
        height: 64px;
        position: relative;
        div{
            position: absolute;
            text-align: center;
            width: 169px;
            height: 32px;
            line-height: 32px;
            top: 16px;
        }
        .nav1{
            left: 278px;
        }
        .nav2{
            left: 441px;
        }
        .nav3{
            left: 603px;
        }
        .nav4{
            left: 766px;
            width: 156px;
        }
        .navbg_blue{
            background: url('../../assets/img/topnav.png');
            color: #fff;
        }
        .navbg_blue2{
            background: url('../../assets/img/topnav4.png');
            color: #fff;
        }
        .navbg_blue3{
            background: url('../../assets/img/topnav5.png');
            color: #fff;
        }
        .navbg_gray{
            background: url('../../assets/img/topnav2.png');
        }
        .navbg_gray2{
            background: url('../../assets/img/topnav3.png');
            
        }
    }
    .tab_box{
        width: 100%;
        height: 60px;
        padding-left: 24px;
        box-sizing: border-box;
        .tab-item{
            float: left;
            width: 104px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            text-align: center;
            border: 1px solid #185BCE;
            cursor: pointer;
            box-sizing: border-box;
        }
        .input_box{
            width: 100%;
            height: 32px;
            margin-bottom: 16px;
            >p{
                float: left;
                line-height: 32px;
            }
            .el-input{
                float: left;
                width: 170px;
                margin-right: 10px;
            }
            .el-input--prefix /deep/.el-input__inner{
                padding-left: 30px;
            }
            /deep/.el-input__inner{
                width: 160px;
                height: 32px !important;
                line-height: 32px !important;
            }
            .el-button{
                float: left;
                margin-left: 12px;
            }
            .right{
                float: right;
                height: 100%;
                line-height: 32px;
                color: #185BCE;
                >p{
                    float: left;
                    margin-right: 16px;
                    cursor: pointer;
                }
                >i{
                    cursor: pointer;
                }
            }
        }
    }
    /deep/.el-tabs__item{
        width: 44px;
        height: 26px;
        line-height: 26px;
        padding: 0 !important;
        text-align: center;
    }
    .main_box{
        padding: 0 24px 24px;
        position: relative;
    }
  
    
    /deep/.el-tabs__content{
        overflow: initial !important;
    }
    /deep/.el-tabs__header{
        border-bottom: none !important;
        
    }
    /deep/.el-tabs__nav{
        border: none !important;
    }
    /deep/.el-tabs__item{
        background: rgba(50, 50, 51, 0.06);
        border: none;
    }
    /deep/.cell{
        line-height: 22px;
    }
    /deep/.el-table__cell{
        padding: 6px 0;
    }
}
.next_box{
    overflow: hidden;
    display: flex;
    justify-content:center;
    .pop_btn{
    display: flex;
    width: 42px;
    height: 32px;
    line-height: 32px;
    margin-right: 38px;
    color: #185BCE;
    cursor: pointer;
    }
    .next_btn{
    display: flex;
    width: 80px;
    height: 32px;
    line-height: 32px;
    justify-content:center;
    color: #FFFFFF;
    background: #185BCE;
    cursor: pointer;
    }
}

</style>