import { render, staticRenderFns } from "./housePriceTable.vue?vue&type=template&id=36e82327&scoped=true&"
import script from "./housePriceTable.vue?vue&type=script&lang=js&"
export * from "./housePriceTable.vue?vue&type=script&lang=js&"
import style0 from "./housePriceTable.vue?vue&type=style&index=0&id=36e82327&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e82327",
  null
  
)

export default component.exports