<template>
  <div class="chart">
    <p class="title">房价走势</p>
    <div id="chartbox"></div>
  </div>
</template>
<script>
export default {
  props: {
    houseChartData: {},
  },
  data() {
    return {
      myChart:{}
    };
  },
  watch:{
    houseChartData:{
      handler: function(nVal, oVal){
        if(this.chart){
          if(nVal){
            // this.setOption(nVal)

          }else{
            // this.setOption(oVal)
          }
        }else{
          this.renderEcharts();
        }
      },
      deep: true
    }
  },
  created(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    //加载历年土地交易信息图表
    renderEcharts(){
      this.myChart = this.$echarts.init(document.getElementById('chartbox'));
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['年度均价']
        },
        xAxis: [
          {
            type: 'category',
            data: this.houseChartData.year,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '元/m²',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '年度均价',
            type: 'line',
            data: this.houseChartData.price,
          }
        ]
      };

      this.myChart.setOption(option,true);
    },

    
  },
};
</script>
<style lang="less" scoped>
.chart{
  width: 1162px;
  .title{
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
  }
}
#chartbox{
  width: 1162px;
  height: 380px;
  padding-left: 10px;
  padding-top: 10px;
}
</style>