<template>
<div>
    <div class="card">
        <div class="nav_box">
            <div class="nav1 navbg_blue">土地信息</div>
            <div class="nav2 navbg_blue2">房价信息</div>
            <div class="nav3 navbg_gray">材料价格信息</div>
            <div class="nav4 navbg_gray2">工程造价指标</div>
        </div>
        <div class="main_box">
                <el-tabs v-model="fjxx" type="card" @tab-click="houseClick">
                    <el-tab-pane label="表格" name="table">
                        <housePriceTable v-if="isShow" :housePriceData="housePriceData" :relationId="relationId"  v-on:listenHouseEvent="getChildHouse"></housePriceTable>
                    </el-tab-pane>
                    <el-tab-pane label="图表" name="chart">          
                        <houseChart :houseChartData="houseChartData"></houseChart>
                    </el-tab-pane>
                    
                </el-tabs>
        </div>
        
    </div>
    <div class="next_box">
        <div class="pop_btn" @click="toNext('land')">上一步</div>
        <div class="next_btn" @click="toNext('materials')">下一步</div>
    </div>
    <div class="feed_box" v-show="isTitle">
        <div class="feed_card">
            <div class="feed_header">
                <div class="feed_title">提示</div>
                <div class="el-icon-close feed_close" @click="close()"></div>
            </div>
            <div class="feed_form">
                当前房价信息的年度范围可能存在缺失，无法与土地信息的年度范围对齐。如有缺失，请您自行补全剩余的房价信息。如不进行缺失的年度房价信息添加时，将按照当前已有的房屋销售年度均价作为计算依据，相应的土地信息年度范围也将会与其对齐。
            </div>
            <div class="feed_btn">
                <div class="feed_submit" @click="close()">确定</div>
            </div>

        </div>
    </div>
</div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import housePriceTable from '@/views/industryAnalysis/housePriceTable.vue'
import houseChart from '@/views/industryAnalysis/houseChart.vue'
export default {
    name:'housePrice',
    components:{
        housePriceTable:housePriceTable,
        houseChart:houseChart,
    },
    data() {
        return {
            fjxx: 'table',
            relationId: '',
            housePriceData:[],
            houseYear: [],
            housePrice: [],
            houseChartData: {},
            isShow:true,
            isTitle:true,
        }
    },
    created() {
   
    },
 
    mounted() {
        this.getUserHousePrice();
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //房价信息标签切换
        houseClick(){
            this.getReport(2,'housePrice',this.fjxx);
            if(this.fjxx=='table'){
                this.isShow = false;
                this.getUserHousePrice();
            }
        },
        //获取房价数据
        getUserHousePrice(){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getUserHousePrice',
                 
                params:{
                    userId:getLocalStore('userinfo').id,
                    userSource: getLocalStore("userinfo").userSource,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                }

            }).then((res) => {
                this.relationId = res.data.data.relationId;
                this.housePriceData = res.data.data;
                //分割出年份和均价图表数据
                this.housePriceData.page.forEach(e => {
                    this.houseYear.push(e.year)
                    this.housePrice.push(e.avgPrice)
                });
                this.houseChartData.year = this.houseYear;
                this.houseChartData.price = this.housePrice;
                this.housePriceData.year = this.houseYear;
                this.isShow = true;
            })
        },
        getChildHouse(res){
            //分割出年份和均价图表数据
            this.houseYear = [];
            this.housePrice = [];
            res.page.forEach(e => {
                this.houseYear.push(e.year)
                this.housePrice.push(e.avgPrice)
            });
            this.houseChartData = res;
            this.housePriceData = res;
            this.houseChartData.year = this.houseYear;
            this.houseChartData.price = this.housePrice;
            this.housePriceData.year = this.houseYear;
        },
        //下一步
        toNext(tag){
            //保存 房价
            this.axios
            .request({
                loading: true,
                method: "post",
                url: "/dms/calculate/userHousePriceComplete",
                data: {
                    id:'',
                    priceNote:'',
                    year:'',
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                    userId: this.userId,
                    userSource: this.userSource,
                },
                })
                .then((res) => {
                    this.$emit("listenNext",tag);
            });
            
        },
        close(){
            this.isTitle = false
        }
   
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.module-container{
    height: 100%;
}
.card{
    width: 100%;
    height: 582px;
    background-color: #fff;
    .nav_box{
        width: 100%;
        height: 64px;
        position: relative;
        div{
            position: absolute;
            text-align: center;
            width: 169px;
            height: 32px;
            line-height: 32px;
            top: 16px;
        }
        .nav1{
            left: 278px;
        }
        .nav2{
            left: 441px;
        }
        .nav3{
            left: 603px;
        }
        .nav4{
            left: 766px;
            width: 156px;
        }
        .navbg_blue{
            background: url('../../assets/img/topnav.png');
            color: #fff;
        }
        .navbg_blue2{
            background: url('../../assets/img/topnav4.png');
            color: #fff;
        }
        .navbg_blue3{
            background: url('../../assets/img/topnav5.png');
            color: #fff;
        }
        .navbg_gray{
            background: url('../../assets/img/topnav2.png');
        }
        .navbg_gray2{
            background: url('../../assets/img/topnav3.png');
            
        }
    }
    .tab_box{
        width: 100%;
        height: 32px;
        padding-left: 24px;
        box-sizing: border-box;
        .tab-item{
            float: left;
            width: 104px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            text-align: center;
            border: 1px solid #185BCE;
            cursor: pointer;
            box-sizing: border-box;
        }
        
        .selected {
            background: #185BCE;
            color: #FFFFFF;
        }
    }
    /deep/.el-tabs__item{
        width: 44px;
        height: 26px;
        line-height: 26px;
        padding: 0 !important;
        text-align: center;
    }
    .main_box{
        padding: 0 24px 24px;
        position: relative;
    }
  
    
    /deep/.el-tabs__content{
        overflow: initial !important;
    }
    /deep/.el-tabs__header{
        border-bottom: none !important;
        
    }
    /deep/.el-tabs__nav{
        border: none !important;
    }
    /deep/.el-tabs__item{
        background: rgba(50, 50, 51, 0.06);
        border: none;
    }
    /deep/.cell{
        line-height: 22px;
    }
    /deep/.el-table__cell{
        padding: 6px 0;
    }
}
.next_box{
    overflow: hidden;
    display: flex;
    justify-content:center;
    .pop_btn{
    display: flex;
    width: 42px;
    height: 32px;
    line-height: 32px;
    margin-right: 38px;
    color: #185BCE;
    cursor: pointer;
    }
    .next_btn{
    display: flex;
    width: 80px;
    height: 32px;
    line-height: 32px;
    justify-content:center;
    color: #FFFFFF;
    background: #185BCE;
    cursor: pointer;
    }
}
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 532px;
        height: 268px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 463px;
            height: 120px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 40px;
            box-sizing: border-box;
            color: #323233;
            line-height: 20px;
            font-size: 14px;
            
        }
        .feed_btn{
            width: 60px;
            height: 32px;
            position: absolute;
            left: 236px;
            bottom: 24px;
            >div{
                width: 60px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_submit{
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
    .el-input{
        margin-bottom: 12px;
    }
    /deep/.el-input__inner{
        width: 240px;
        height: 32px !important;
        line-height: 32px !important;
    }
}
</style>