<template>
    <div>
        <el-table height="420px" ref="table" :data="costIndicatorData.page" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column prop="province" label="区域" align="center" >
                <template slot-scope="scope">
                    <span>{{ costIndicatorData.type==0 ? scope.row.province : scope.row.province+scope.row.city }}</span>    
                </template>
            </el-table-column>
            <el-table-column prop="year" label="年度" align="center" width="100"></el-table-column>
            <el-table-column prop="houseType" label="房屋类型" align="center" width="100"></el-table-column>
            <el-table-column prop="multilayer" label="多层住宅（砌体≤6层）" align="right" ></el-table-column>
            <el-table-column prop="smallhighrise" label="小高层住宅（框架≤18层）" align="right" ></el-table-column>
            <el-table-column prop="highrise" label="高层住宅（框架＞18层）" align="right" ></el-table-column>

            <el-table-column label="操作" v-if="costIndicatorData.type==1" align="center" width="120">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="detail(scope.row)">修改 </el-button>
                    <el-button size="mini" type="text" class="delete" @click="deleteData(scope.row)">删除 </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="feed_box" v-show="isChange">
            <div class="feed_card">
                <div class="feed_header">
                    <div class="feed_title">{{title}}</div>
                    <div class="el-icon-close feed_close" @click="close()"></div>
                </div>
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question">多层造价：</div>
                        <div class="feed_question">小高层造价:</div>
                        <div class="feed_question">高层造价：</div>
                    </div>
                    <div class="feed_right">
                        <el-input v-model="multilayer" maxlength="30" placeholder="输入多层造价"></el-input>
                        <el-input v-model="smallhighrise" maxlength="30" placeholder="输入小高层造价"></el-input>
                        <el-input v-model="highrise" maxlength="30" placeholder="输入高层造价"></el-input>
                    </div>
                </div>
                <div class="feed_btn">
                    <div class="feed_cancel" @click="close()">取消</div>
                    <div class="feed_submit" @click="changeData()">{{button}}</div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>

import {getStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        costIndicatorData: {},
    },
    data() {
        return {
            isChange: false,
            year: '',
            price: '',
            priceNote: '',
            id: '',
            relationId: '',
            title: '修改数据',
            button: '提交',
            yearsArr: [],
            toParentsData: {},
            multilayer: '',
            smallhighrise: '',
            highrise: '',
        }
    },
    created(){
        
    },
    mounted() {
        this.relationId = this.costIndicatorData.relationId;
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        detail(res){
            this.multilayer = res.multilayer;
            this.smallhighrise = res.smallhighrise;
            this.highrise = res.highrise;
            this.id = res.id;
            this.relationId = res.relationId;
            this.title = '修改数据';
            this.button = '提交';
            this.isChange = true;

        },
        deleteData(res){
            let that = this;
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.request({
                    loading: true,
                    method: "post", 
                    url: '/dms/calculate/deleteUserCostIndicator',
                     
                    data:{
                        multilayer:res.multilayer,
                        id:res.id,
                        smallhighrise:res.smallhighrise,
                        orderNum: getLocalStore("orderNum"),
                        reportVersion: getLocalStore("reportVersion"),
                        highrise:res.highrise,
                    }

                }).then((res) => {
                    this.toParentsData = res.data.data;
                    that.$emit("listenCostEvent",this.toParentsData)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        close(){
            this.isChange = false
        },
        //获取图表数据
        changeData(){
            let that = this;
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/updateUserCostIndicator',
                 
                data:{
                    multilayer:this.multilayer,
                    id:this.id,
                    smallhighrise:this.smallhighrise,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                    highrise:this.highrise,
                }

            }).then((res) => {
                this.isChange = false;
                this.toParentsData = res.data.data;
                that.$emit("listenCostEvent",this.toParentsData)
            })
            
        },
    }
}
</script>

<style scoped  lang="less">
.delete{
    color: #D40000 !important;
}
.add{
    position: absolute;
    right: 0;
    top: -42px;
}
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 400px;
        height: 300px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 350px;
            height: 240px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 20px;
            box-sizing: border-box;
            .feed_left{
                width: 110px;
                float: left;
                text-align: right;
                .feed_question{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                    >i{
                        color: #D40000;
                    }
                }
                .feed_proposal{
                    padding-top: 6px;
                    height: 74.4px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
            }
            .feed_right{
                width: 240px;
                float: right;
                .feed_radio{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                }
            }
        }
        .feed_btn{
            width: 168px;
            height: 32px;
            position: absolute;
            left: 130px;
            bottom: 16px;
            >div{
                float: left;
                width: 76px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_cancel{
                border: 1px solid #DCDEE0;
                color: #323233;
            }
            .feed_submit{
                margin-left: 16px;
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
    .el-input{
        margin-bottom: 12px;
    }
    /deep/.el-input__inner{
        width: 240px;
        height: 32px !important;
        line-height: 32px !important;
    }
}
</style>