<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">
        测算报告 / <span style="color:#646566">设置自定义测算参数</span>
        <el-button class="backbtn" type="text" @click="back()">返回上级</el-button>
      </div>
      <div class="list_box">
        <div class="search-box">
          <div class="module-title">自定义测算</div>
        </div>
        <div class="table_box">
          <div class="set_item">
            <el-tooltip placement="bottom-end" content="注：目前区域测算报告支持山东省、河北省，更多地区测算陆续开通中。" effect="light">
              <i class="el-icon-question"></i>
            </el-tooltip>
            <span>请选择您要测算的地区：</span>
            <el-cascader
                size="large"
                :options="areaData"
                v-model="selectedOptions"
                @change="areaHandleChange"
            >
            </el-cascader>
          </div>
          <div class="feedback">
            <p>没有找到想测算的地区？<span @click="openFeed">点击反馈</span></p>
          </div>
          <div class="set_item">
            <el-tooltip placement="bottom-end" effect="light">
              <div slot="content">快速测算：根据您所在地区进行快速测算<br/>精准测算：根据您设置的一系列参数进行更为精准的测算</div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <span>请选择您要测算的模式：</span>
            <el-radio-group v-model="radio" @change="radioChange">
                <el-radio label="1" key="1">快速测算</el-radio>
                <el-radio label="2" key="2">精准测算</el-radio>
            </el-radio-group>
            
            <span class="setData" :class="radio=='2'&&selectedOptions.length>0 ? 'gary' : ''" @click="openSet">设置精准测算参数</span>
          </div>
          <el-button class="start_btn" type="primary" :disabled="isStart" @click="startCalc">开始测算</el-button>
          <!-- <el-button class="start_btn" disabled="false" @click="startCalc">开始测算</el-button> -->
        </div>
      </div>
    </div>
    <el-dialog title="精准测算参数" :visible.sync="showSetData" @close="closeSet" width="1200px">
       <div class="dialog">
         <div class="component">
           <landInformation v-if="isShow('land')" v-on:listenNext="toNext"></landInformation>
           <housePrice v-if="isShow('house')" v-on:listenNext="toNext"></housePrice>
           <materials v-if="isShow('materials')" v-on:listenNext="toNext"></materials>
           <project v-if="isShow('project')" v-on:listenNext="toNext"></project>
         </div>
         
       </div>
    </el-dialog>
    <div class="feed_box" v-show="isFeed">
        <div class="feed_card">
            <div class="feed_header">
                <div class="feed_title">反馈</div>
                <div class="el-icon-close feed_close" @click="closeFeed"></div>
            </div>
            <div class="feed_form">
                <div class="feed_left">
                    <div class="feed_question">测算区域：</div>
                    <div class="feed_question">机构名称：</div>
                    <div class="feed_question">姓名：</div>
                    <div class="feed_question">联系电话：</div>
                </div>
                <div class="feed_right">
                    <el-cascader
                        size="large"
                        :options="cityData"
                        v-model="feed_selectedOptions"
                        @change="feedAreaHandleChange">
                    </el-cascader>
                    <el-input v-model="company" placeholder="请输入您所在的机构名称"></el-input>
                    <el-input v-model="name" placeholder="请输入您的姓名"></el-input>
                    
                    <el-input v-model="phoneNum" placeholder="请输入您的联系电话"></el-input>
                </div>
            </div>
            <div class="feed_btn">
                <div class="feed_cancel" @click="closeFeed">取消</div>
                <div class="feed_submit" @click="feedBack">提交</div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, getLocalStore,setLocalStore } from "@/util/store";
import { mapMutations } from "vuex";
import { regionData, CodeToText, TextToCode } from "element-china-area-data"; // 地址级联选择器
import landInformation from '@/views/industryAnalysis/landInformation.vue'
import housePrice from '@/views/industryAnalysis/housePrice.vue'
import materials from '@/views/industryAnalysis/materials.vue'
import project from '@/views/industryAnalysis/project.vue'
import {Loading} from "element-ui";
import { contourDensity } from '../../util/d3.v5';
export default {
  components:{
      landInformation:landInformation,
      housePrice:housePrice,
      materials:materials,
      project:project
  },
  data() {
    return {
      isStart:true,
      address:'',
      areaData: [],
      selectedOptions: [],
      feed_selectedOptions:[],
      feed_province:'',
      feed_city:'',
      feed_county:'',
      feed_province_code:'',
      feed_city_code:'',
      feed_county_code:'',
      province:'',
      city:'',
      county:'',
      province_code:'',
      city_code:'',
      county_code:'',
      radio:'',
      showSetData:false,
      showType: 'land',
      isFeed:false,
      company:'',
      name:'',
      phoneNum:'',
      cityData:[],
      isCityChange:false,
      userId:'',
      userSource:'',
    };
  },
  created() {
    this.cityData = regionData;
    this.userSource = getLocalStore("userinfo").userSource;
    this.userId = getLocalStore("userinfo").id;
    this.getAvailableArea()
  },
  mounted() {},
  methods: {
    ...mapMutations(["addPath","reducePath", "addMenuList", "reduceMenuList"]),
    back(){
      this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
      this.$router.push({path: '/industryAnalysis'});
    },
    //获取省市数据
    getAvailableArea() {
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/getAvailableArea",
        })
        .then((res) => {
          this.areaData = res.data.data
        });
    },
    //开始测算
    startCalc(){
      if(this.radio!=''){
        if(this.radio==1){
          this.getFastCalculate()
        }else if(this.radio==2){
          this.getAccurateCalculate()
        }
        
      }else{
        this.$message.error('请选择测算方式！')
      }
    },
    //快速测算
    getFastCalculate() {
      let getProject_login = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/fastCalculate",
          params: {
            orderNum: getLocalStore("orderNum"),
            reportVersion: getLocalStore("reportVersion"),
            userId: this.userId,
            userSource: this.userSource,
          },
        })
        .then((res) => {
          getProject_login.close();
          if(res.data.data.error){
            this.$message.error(res.data.data.error)
          }else{
            this.addMenuList("/industryAnalysis");
            setStore("currentMenu", "/industryAnalysis");
            this.$router.push({path: "/industryAnalysis"});
          }
          
        });
    },
    //精准测算
    getAccurateCalculate() {
      let getProject_login = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/accurateCalculate",
          params: {
            orderNum: getLocalStore("orderNum"),
            reportVersion: getLocalStore("reportVersion"),
            userId: this.userId,
            userSource: this.userSource,
          },
        })
        .then((res) => {
          getProject_login.close();
          this.addMenuList("/industryAnalysis");
          setStore("currentMenu", "/industryAnalysis");
          this.$router.push({path: "/industryAnalysis"});
        });
    },
    //选择省市区
    areaHandleChange(value) {
      if(value[2].length>0){
        this.getCityName(value[2])
      }else{
        this.getCityName(value[1])
      }
      
    },
    //通过code获取省市区名称
    getCityName(code){
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/getSynthesisAreaByCode",
          params: {
            code:code
          },
        })
        .then((res) => {
          var $data = res.data.data;
          this.province = $data.provinceCalculate;
          this.province_code = $data.provinceCalculateCode;
          this.city = $data.cityCalculate;
          this.city_code = $data.cityCalculateCode;
          setLocalStore("cityAlibaba",  $data.cityAlibaba);
          setLocalStore("cityAlibabaCode",  $data.cityAlibabaCode);

          if($data.type=='county'){
            this.county = $data.countyCalculate;
            this.county_code = $data.countyCalculateCode;
            setLocalStore("countyAlibaba",  $data.countyAlibaba);
            setLocalStore("countyAlibabaCode",  $data.countyAlibabaCode);

          }else{
            this.county = '全部'
          }
          this.isCityChange = true;

          //更新区域信息
          if(this.radio!=''&&this.selectedOptions.length>0){
            this.isStart = false;
            this.showType = ''
            this.updateAreaInfo();
          }
        });
    },
    //反馈省市区选择
    feedAreaHandleChange(value){
      if(CodeToText[value[0]]){
        this.feed_province = CodeToText[value[0]];
      }
      if(CodeToText[value[1]]){
        this.feed_city = CodeToText[value[1]];
      }
      if(CodeToText[value[2]]){
        this.feed_county = CodeToText[value[2]];
      }
      this.feed_province_code = this.feed_selectedOptions[0];
      this.feed_city_code = this.feed_selectedOptions[1];
      this.feed_county_code = this.feed_selectedOptions[2];
    },
    //反馈
    feedBack(){
      var myreg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
      if(this.feed_city.length==0){
        this.$message.error('请选择区域信息！')
        return false;
      }else if(this.company.length==0){
        this.$message.error('请输入机构名称！')
        return false;
      }else if(this.company.length>33){
        this.$message.error('机构名称不能超过33个字符！')
        return false;
      }else if(this.name.length==0){
        this.$message.error('请输入您的姓名！')
        return false;
      }else if(this.name.length>19){
        this.$message.error('姓名不能超过19个字符！')
        return false;
      }else if(this.name.length==0){
        this.$message.error('请输入您的姓名！')
        return false;
      }else if(!myreg.test(this.phoneNum)){
        this.$message.error('手机号格式有误');
        return false;
      }
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/insertCalculateDemand",
          data: {
            province:this.feed_province,
            provinceCode:this.feed_province_code,
            city:this.feed_city,
            cityCode:this.feed_city_code,
            county:this.feed_county,
            countyCode:this.feed_county_code,
            contactPhone:this.phoneNum,
            organName:this.company,
            userName:this.name,
            userId: this.userId,
            userSource: this.userSource,
          },
        })
        .then((res) => {
            if(res.data.status=="success"){
              this.isFeed = false;
              this.$message.success('反馈成功！')
            }else{
              this.$message.error('反馈失败！')
            }
        });
    },
    //选择测算方式
    radioChange(val){
      //区域信息改变后再调用
      if(this.isCityChange){
        this.isCityChange = false;
        this.updateAreaInfo();
      }
      if(this.radio!=''&&this.selectedOptions.length>0){
        this.isStart = false
      }
    },
    //更新区域信息
    updateAreaInfo(){
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/updateAreaInfo",
          data: {
            province:this.province,
            provinceCode:this.province_code,
            city:this.city,
            cityCode:this.city_code,
            county:this.county,
            countyCode:this.county_code,
            delFlag:'',
            id:'',
            payFlag:'',
            orderNum: getLocalStore("orderNum"),
            reportVersion: getLocalStore("reportVersion"),
            userId: this.userId,
            userSource: this.userSource,
          },
        })
        .then((res) => {
            this.showType = 'land'
            setLocalStore("orderNum", res.data.data.orderNum);
            setLocalStore("reportVersion", res.data.data.reportVersion);
        });
    },
    //打开设置参数
    openSet(){
      if(this.radio=='2' && this.selectedOptions.length>0){
        this.showSetData = true
      }
    },
    //关闭设置参数
    closeSet(){
      this.showSetData = false;
      // this.showType = 'land'
    },
    //下一步跳转
    toNext(path){
      if(path=='success'){
        this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/setComplete",
          params: {
            orderNum: getLocalStore("orderNum"),
            reportVersion: getLocalStore("reportVersion"),
            userId: this.userId,
            userSource: this.userSource,
          },
        })
        .then((res) => {
          this.showSetData = false;
          this.showType = 'land'
        });
        
      }else{
        this.showType = path;
      }
    },
    //参数步骤显示
    isShow(tag){
      if(tag==this.showType){
        return true
      }else{
        return false
      }
    },
    //打开关闭反馈
    openFeed(){
      this.isFeed = true;
    },
    closeFeed(){
      this.isFeed = false;
    },
 
 
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #969799;
  margin: 16px 0;
  position: relative;
}
.backbtn{
  position: absolute;
  right: 0;
  top: -10px;
}
.list_box {
  width: 100%;
  height: auto;
  background: #ffffff;
}
.search-box {
    padding: 10px 24px 0;
    border-bottom: 1px solid #ebebeb;
    overflow: hidden;
    height: 56px;
    box-sizing: border-box;
}

.module-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  float: left;
}
.table_box{
  width: 100%;
  height: 602px;
  padding-left: 383px;
  padding-top: 170px;
  box-sizing: border-box;
  position: relative;
  .feedback{
    position: absolute;
    left: 419px;
    top: 210px;
    font-size: 12px;
    color: #999999;
    span{
      color: #185BCE;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .set_item{
    height: 32px;
    margin-bottom: 60px;
    i{
      font-size: 18px;
      color: #FFBF00;
      margin-right: 17px;
    }
    .setData{
      text-decoration: underline;
      cursor: pointer;
      color: #999999;
    }
    .gary{
      color: #185BCE;
    }
  }
  .start_btn{
    width: 74px;
    height: 32px;
    background: #185BCE;
    border-radius: 2px;
    color: #ffffff;
    padding: 0;
    text-align: center;
    margin: 205px 0 0 187px;
  }
  .is-disabled{
    background: #CCCCCC !important;
    border: #CCCCCC !important;
}
}
.el-cascader {
  width: 215px;
  line-height: 30px;
}
.el-radio{
  margin-right: 16px;
  color: #000000 !important;
}
.dialog{
  width: 100%;
  height: 636px;
  border-top: 1px solid #EBEDF0;
  .component{
    width: 100%;
    height: auto;
  }
  
}
/deep/.el-dialog__body{
  padding: 0 !important;
}
/deep/.el-dialog__header{
  padding-bottom: 20px !important;
}
/deep/.el-radio__input.is-checked+.el-radio__label{
  color: #000000 !important;
}
/deep/.el-radio__input.is-checked .el-radio__inner{
  border-color: #185BCE;
  background: #185BCE;
}
/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 12px;
}
/deep/.el-form-item__label {
  margin-top: 2px;
  padding-right: 0px !important;
}
/deep/.el-input__icon {
  line-height: 32px;
}
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 740px;
        height: 400px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 370px;
            height: 240px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 60px;
            box-sizing: border-box;
            .feed_left{
                width: 84px;
                float: left;
                text-align: right;
                .feed_question{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                    >i{
                        color: #D40000;
                    }
                }
                .feed_proposal{
                    padding-top: 6px;
                    height: 74.4px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
            }
            .feed_right{
                width: 282px;
                float: right;
                .feed_radio{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                }
            }
        }
        .feed_btn{
            width: 168px;
            height: 32px;
            position: absolute;
            left: 282px;
            bottom: 16px;
            >div{
                float: left;
                width: 76px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_cancel{
                border: 1px solid #DCDEE0;
                color: #323233;
            }
            .feed_submit{
                margin-left: 16px;
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
    .el-input{
        margin-bottom: 12px;
    }
    /deep/.el-input__inner{
        width: 282px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-cascader{
        width: 282px;
        margin-bottom: 12px;
        line-height: 32px;
    }
}
</style>