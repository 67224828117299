<template>
<div>
    <div class="card">
        <div class="nav_box">
            <div class="nav1 navbg_blue">土地信息</div>
            <div class="nav2 navbg_blue2">房价信息</div>
            <div class="nav3 navbg_blue2">材料价格信息</div>
            <div class="nav4 navbg_blue3">工程造价指标</div>
        </div>
        <div class="main_box">
                <div class="gc_box">
                    <div class="search_box">
                        <p>测算指标：</p>
                        <el-select @change="gcSelectChange" v-model="costIndex" placeholder="请选择">
                            <el-option label="当地住宅" key="当地住宅" value="当地住宅">当地住宅</el-option>
                            <el-option label="省会住宅" key="省会住宅" value="省会住宅">省会住宅</el-option>
                            <el-option label="系数计算住宅" key="系数计算住宅" value="系数计算住宅">系数计算住宅</el-option>
                        </el-select>
                    </div>
                    <el-button class="search_btn" type="primary" size="small" @click="setDefault">设为默认</el-button>
                    <el-button v-if="isXiShu" class="search_btn" size="small" @click="addCostIndicator">新增</el-button>
                </div>
                <costIndicatorTable :costIndicatorData="costIndicatorData" v-on:listenCostEvent="getCostChildData"></costIndicatorTable>
        </div>
        
       
    </div>
    <div class="next_box">
        <div class="pop_btn" @click="toNext('materials')">上一步</div>
        <div class="next_btn" @click="toNext('success')">完成设置</div>
    </div>
    <el-drawer   
        title="新增标准"
        :visible.sync="iscostIndicator"
        :direction="direction"
        size="913px"
        style="position:absolute"
        z-index = "0"
        :before-close="handleClose">
        <add v-on:listenAddEvent="changeAdd"></add>
    </el-drawer>
    <div class="feed_box" v-show="isTitle">
        <div class="feed_card">
            <div class="feed_header">
                <div class="feed_title">提示</div>
                <div class="el-icon-close feed_close" @click="close()"></div>
            </div>
            <div class="feed_form">
                新增指标的年度范围请按照土地信息和房价信息的年度范围进行添加，如果您没有按照土地信息和房价信息的年度范围添加指标，将会按照您所添加的年度范围作为依据进行测算。
            </div>
            <div class="feed_btn">
                <div class="feed_submit" @click="close()">确定</div>
            </div>

        </div>
    </div>
</div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import costIndicatorTable from '@/views/industryAnalysis/costIndicatorTable.vue'
import add from '@/views/industryAnalysis/add.vue'
export default {
    name:'housePrice',
    components:{
        costIndicatorTable:costIndicatorTable,
        add:add
    },
    data() {
        return {
            relationId: '',
            costIndex: '省会住宅',
            costIndicatorData: {},
            costIndicatorType: 0,
            isXiShu: false,
            iscostIndicator: false,
            direction: 'rtl',
            isTitle:true,
            isSuccess:false,
           
        }
    },
    created() {
   
    },
 
    mounted() {
        //获取工程造价信息
        this.getUserCostIndicator(0)
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //工程造价选择
        gcSelectChange(val){
            if(val=='省会住宅'){
                this.getReport(2,'costIndicator','provincial');
                this.isXiShu = false;
                this.getUserCostIndicator(0);
                this.costIndicatorType = 0;
            }else if(val=='当地住宅'){
                this.getReport(2,'costIndicator','local');
                this.isXiShu = false;
                this.getUserCostIndicator(2);
                this.costIndicatorType = 2;
            }else{
                this.getReport(2,'costIndicator','coefficientCalculation');
                this.isXiShu = true;
                this.getUserCostIndicator(1)
                this.costIndicatorType = 1;
            }
            //  this.iscostIndicator = true;
        },
        getUserCostIndicator(type){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getUserCostIndicator',
                 
                params:{
                    userId:getLocalStore('userinfo').id,
                    userSource: getLocalStore("userinfo").userSource,
                    type: type,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                }
            }).then((res) => {
               this.costIndicatorData = res.data.data;
               this.costIndicatorData.type = type;
            })
        },
        getCostChildData(res){
            this.costIndicatorData = res;
            this.costIndicatorData.type = 1;
        },
        addCostIndicator(){
            this.iscostIndicator = true;
            this.getReport(2,'coefficientCalculation','add');
        },
        changeAdd(res){
            this.getUserCostIndicator(1);
            this.iscostIndicator = !res;
        },
        //设为默认
        setDefault(){
            this.getReport(2,'costIndicator','setDefault');
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/setDefaultCost',
                 
                params:{
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                    type: this.costIndicatorType 
                }
            }).then((res) => {
               if(res.data.data.success){
                    this.$message.success(res.data.data.success)
                    this.isSuccess = true;
               }else{
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.data.error);
                        this.isSuccess = false;

                    }
               }
            })
        },
        handleClose(done) {
            this.iscostIndicator = false
        },
     
        //下一步
        toNext(tag){
            if(tag=='success'){
                if(this.isSuccess){
                    this.$emit("listenNext",tag);
                }else{
                    this.$message.error('请先设置造价数据！');
                }
            }else{
                this.$emit("listenNext",tag);
            }
        },
        close(){
            this.isTitle = false
        }
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.module-container{
    height: 100%;
}
.card{
    width: 100%;
    height: 582px;
    background-color: #fff;
    .nav_box{
        width: 100%;
        height: 64px;
        position: relative;
        div{
            position: absolute;
            text-align: center;
            width: 169px;
            height: 32px;
            line-height: 32px;
            top: 16px;
        }
        .nav1{
            left: 278px;
        }
        .nav2{
            left: 441px;
        }
        .nav3{
            left: 603px;
        }
        .nav4{
            left: 766px;
            width: 156px;
        }
        .navbg_blue{
            background: url('../../assets/img/topnav.png');
            color: #fff;
        }
        .navbg_blue2{
            background: url('../../assets/img/topnav4.png');
            color: #fff;
        }
        .navbg_blue3{
            background: url('../../assets/img/topnav5.png');
            color: #fff;
        }
        .navbg_gray{
            background: url('../../assets/img/topnav2.png');
        }
        .navbg_gray2{
            background: url('../../assets/img/topnav3.png');
            
        }
    }
    .tab_box{
        width: 100%;
        height: 60px;
        padding-left: 24px;
        box-sizing: border-box;
        .tab-item{
            float: left;
            width: 104px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            text-align: center;
            border: 1px solid #185BCE;
            cursor: pointer;
            box-sizing: border-box;
        }
        .input_box{
            width: 100%;
            height: 32px;
            margin-bottom: 16px;
            >p{
                float: left;
                line-height: 32px;
            }
            .el-input{
                float: left;
                width: 170px;
                margin-right: 10px;
            }
            .el-input--prefix /deep/.el-input__inner{
                padding-left: 30px;
            }
            /deep/.el-input__inner{
                width: 160px;
                height: 32px !important;
                line-height: 32px !important;
            }
            .el-button{
                float: left;
                margin-left: 12px;
            }
            .right{
                float: right;
                height: 100%;
                line-height: 32px;
                color: #185BCE;
                >p{
                    float: left;
                    margin-right: 16px;
                    cursor: pointer;
                }
                >i{
                    cursor: pointer;
                }
            }
        }
        
    }
    .search_box{
        float: left;
        height: auto;
        padding-top: 12px;
        overflow: hidden;
        .el-input{
            float: left;
            width: 170px;
            margin-right: 10px;
        }
        /deep/.el-input__inner{
            width: 160px;
            height: 32px !important;
            line-height: 32px !important;
        }
        /deep/.el-input__icon{
            line-height: 32px !important;
        }
        .el-button{
            float: left;
            margin-right: 24px;
        }
        p{
            float: left;
            height: 32px;
            line-height: 32px;
            margin-right: 10px;
        }
        .el-select{
            float: left;
            margin-right: 24px;
        }
    }
    .gc_title{
        font-size: 16px;
        float: left;
        padding-top: 5px;
    }
    .gc_box{
        width: 100%;
        height: 60px;
        position: relative;
        .el-button{
            float: right;
            margin-left: 12px;
        }
        .unit{
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    /deep/.el-tabs__item{
        width: 44px;
        height: 26px;
        line-height: 26px;
        padding: 0 !important;
        text-align: center;
    }
    .main_box{
        padding: 0 24px 24px;
        position: relative;
    }
  
    
    /deep/.el-tabs__content{
        overflow: initial !important;
    }
    /deep/.el-tabs__header{
        border-bottom: none !important;
        
    }
    /deep/.el-tabs__nav{
        border: none !important;
    }
    /deep/.el-tabs__item{
        background: rgba(50, 50, 51, 0.06);
        border: none;
    }
    /deep/.cell{
        line-height: 22px;
    }
    /deep/.el-table__cell{
        padding: 6px 0;
    }
}
.next_box{
    overflow: hidden;
    display: flex;
    justify-content:center;
    .pop_btn{
    display: flex;
    width: 42px;
    height: 32px;
    line-height: 32px;
    margin-right: 38px;
    color: #185BCE;
    cursor: pointer;
    }
    .next_btn{
    display: flex;
    width: 80px;
    height: 32px;
    line-height: 32px;
    justify-content:center;
    color: #FFFFFF;
    background: #185BCE;
    cursor: pointer;
    }
}
/deep/.el-drawer__header{
    margin-bottom: 20px;
}
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 532px;
        height: 268px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 463px;
            height: 120px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 40px;
            box-sizing: border-box;
            color: #323233;
            line-height: 20px;
            font-size: 14px;
            
        }
        .feed_btn{
            width: 60px;
            height: 32px;
            position: absolute;
            left: 236px;
            bottom: 24px;
            >div{
                width: 60px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_submit{
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
    .el-input{
        margin-bottom: 12px;
    }
    /deep/.el-input__inner{
        width: 240px;
        height: 32px !important;
        line-height: 32px !important;
    }
}

</style>