<template>
    <div>
        <el-table ref="table" height="386px" :data="materialData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column type="index" label="序号" :index="indexMethod" align="center" width="80"></el-table-column>
            <el-table-column prop="year" label="日期" align="left" width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.year+'-'+scope.row.month }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="材料名称"  align="left"></el-table-column>
            <el-table-column prop="model" label="型号"  align="left"></el-table-column>
            <el-table-column prop="unit" label="单位"  align="left"></el-table-column>
            <el-table-column prop="price" label="含税信息价（元）"  align="right"></el-table-column>
            <el-table-column prop="priceAfterTax" label="除税信息价（元）"  align="right"></el-table-column>
        </el-table>
    </div>

</template>

<script>

export default {
    props: {
        materialData: {},
        trekkingPage:{}
    },
    data() {
        return {
        }
    },
    created(){
        
    },
    mounted() {
    },
    methods:{
        indexMethod(index) {
              return (this.trekkingPage.trekkingPageNum - 1) * this.trekkingPage.trekkingPageSize + index + 1;
        },
      
    }
}
</script>

<style scoped  lang="less">

</style>