<template>
    <div>
        <el-table ref="table" height="420px" :data="housePriceData.page" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column prop="year" label="年度" align="center" ></el-table-column>
            <el-table-column prop="avgPrice" label="年度均价（元/平方米）"  align="right"></el-table-column>

            <el-table-column label="备注" :show-overflow-tooltip="true" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.priceNote || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="detail(scope.row)">校准</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-button size="mini" class="add" type="primary" @click="addRow()">新增年度</el-button>
        <div class="feed_box" v-show="isChange">
            <div class="feed_card">
                <div class="feed_header">
                    <div class="feed_title">{{title}}</div>
                    <div class="el-icon-close feed_close" @click="close()"></div>
                </div>
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question">年度：</div>
                        <div class="feed_question">年度均价：</div>
                        <div class="feed_question">备注：</div>
                    </div>
                    <div class="feed_right">
                        <el-input v-model="year" type="number" :disabled="!isAdd" maxlength="30" placeholder="输入年度"></el-input>
                        <el-input v-model="price" type="number" maxlength="30" placeholder="输入年度均价"></el-input>
                        <el-input v-model="priceNote" maxlength="30" placeholder="输入备注"></el-input>
                    </div>
                </div>
                <div class="feed_btn">
                    <div class="feed_cancel" @click="close()">取消</div>
                    <div class="feed_submit" @click="changeData()">{{button}}</div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>

import {getStore,setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        housePriceData: {},
    },
    data() {
        return {
            isChange: false,
            year: '',
            price: '',
            priceNote: '',
            id: '',
            relationId: '',
            title: '修改数据',
            button: '提交',
            isAdd: false,
            yearsArr: [],
            toParentsData: {}
        }
    },
    created(){
        
    },
    mounted() {
        this.relationId = this.housePriceData.relationId;
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        detail(res){
            this.getReport(2,'housePrice','calibration');
            this.year = res.year;
            this.price = res.avgPrice;
            this.priceNote = res.priceNote;
            this.id = res.id;
            this.relationId = res.relationId;
            this.title = '修改数据';
            this.button = '提交';
            this.isChange = true;
            this.isAdd = false;

        },
        addRow(){
            this.getReport(2,'housePrice','allYear');
            this.year = '';
            this.price = '';
            this.priceNote = '';
            this.id = ''
            this.relationId = this.housePriceData.relationId;
            this.title = '添加年度';
            this.button = '添加';
            this.isChange = true;
            this.isAdd = true;
            //确认已有年度信息
            let that = this;
            this.housePriceData.page.forEach(function(e){
                that.yearsArr.push(e.year)
            })
        },
        close(){
            this.isChange = false;
            this.getReport(2,'housePrice','cancelCalibration');
        },
        //获取图表数据
        changeData(){
            this.getReport(2,'housePrice','confirmCalibration');
            let url = '';
            let that = this;
            //判断新增年份是否存在
            if(this.year.length>0&&this.price.toString().length>0){
                if(this.isAdd&&this.housePriceData.year.includes(this.year)){
                    this.isChange = false;
                    this.$message.error('该年度已存在，请直接修改！')
                }else{
                    if(this.isAdd){
                        url = '/dms/calculate/insertUserHousePrice'
                    }else{
                        url = '/dms/calculate/updateUserHousePrice'
                    }
                    this.axios.request({
                        loading: true,
                        method: "post", 
                        url: url,
                        data:{
                            avgPrice:this.price,
                            id:this.id,
                            priceNote:this.priceNote,
                            orderNum: getLocalStore("orderNum"),
                            reportVersion: getLocalStore("reportVersion"),
                            year:this.year,
                            userId: getLocalStore('userinfo').id,
                            userSource: getLocalStore("userinfo").userSource,
                        }

                    }).then((res) => {
                        // this.housePriceData = res.data.data;
                        this.isChange = false;
                        this.toParentsData = res.data.data;
                        that.$emit("listenHouseEvent",this.toParentsData)
                    })
                }
            }else{
                this.$message.error('请输入年度和均价！')
            }

            
            
        },
    }
}
</script>

<style scoped  lang="less">
.add{
    position: absolute;
    right: 0;
    top: -42px;
}
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 400px;
        height: 300px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 350px;
            height: 240px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 20px;
            box-sizing: border-box;
            .feed_left{
                width: 110px;
                float: left;
                text-align: right;
                .feed_question{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                    >i{
                        color: #D40000;
                    }
                }
                .feed_proposal{
                    padding-top: 6px;
                    height: 74.4px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
            }
            .feed_right{
                width: 240px;
                float: right;
                .feed_radio{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                }
            }
        }
        .feed_btn{
            width: 168px;
            height: 32px;
            position: absolute;
            left: 130px;
            bottom: 16px;
            >div{
                float: left;
                width: 76px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_cancel{
                border: 1px solid #DCDEE0;
                color: #323233;
            }
            .feed_submit{
                margin-left: 16px;
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
    .el-input{
        margin-bottom: 12px;
    }
    /deep/.el-input__inner{
        width: 240px;
        height: 32px !important;
        line-height: 32px !important;
    }
}
</style>