<template>
    <div>
        <div class="add_box">
            <div class="add_first" v-show="isFirst">
                <div class="step_box">
                    <div class="step_item one active">
                        <div class="step_num">1</div>
                        <div class="step_name">基本信息</div>
                    </div>
                    <div class="line first"></div>
                    <div class="step_item two">
                        <div class="step_num">2</div>
                        <div class="step_name">详细信息</div>
                    </div>
                    <div class="line second"></div>
                    <div class="step_item three">
                        <div class="step_num">3</div>
                        <div class="step_name">完成新增</div>
                    </div>
                </div>
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question">区域：</div>
                        <div class="feed_question">年度：</div>
                        <div class="feed_question">房屋类型：</div>
                        <div class="feed_question">建筑结构：</div>
                    </div>
                    <div class="feed_right">
                        <div class="value">{{costIndicatorDictData.area}}</div>
                        <el-select @change="selectYearChange" v-model="costIndicatorYear"  placeholder="请选择">
                            <el-option
                                v-for="(item,key) in costIndicatorDictData.years"
                                :key="key"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <el-select @change="selectHouseChange" v-model="costIndicatorHouseTypes"  placeholder="请选择">
                            <el-option
                                v-for="(item,key) in costIndicatorDictData.houseTypes"
                                :key="key"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <el-select @change="selectStructuresChange" v-model="costIndicatorStructures"  placeholder="请选择">
                            <el-option
                                v-for="(item,key) in costIndicatorDictData.structures"
                                :key="key"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        
                    </div>
                </div>
                <el-button class="search_btn" type="primary" size="small" @click="next(1)">下一步</el-button>
                <el-button class="search_btn back" size="small" @click="back(false)">取消</el-button>
            </div>
            <div class="add_first" v-show="isSecond">
                <div class="step_box">
                    <div class="step_item one active">
                        <div class="step_icon el-icon-circle-check"></div>
                        <div class="step_name">基本信息</div>
                    </div>
                    <div class="line first active"></div>
                    <div class="step_item two active">
                        <div class="step_num">2</div>
                        <div class="step_name">详细信息</div>
                    </div>
                    <div class="line second"></div>
                    <div class="step_item three">
                        <div class="step_num">3</div>
                        <div class="step_name">完成新增</div>
                    </div>
                </div>
                <div class="edit_title">
                    <p class="name">区域：</p>
                    <p class="name desc">{{costIndicatorDictData.area}}</p>
                    <p class="name">年度：</p>
                    <p class="name desc">{{costIndicatorYear}}</p>
                    <p class="name">房屋类型：</p>
                    <p class="name desc">{{costIndicatorHouseTypes}}</p>
                    <p class="name">建筑结构：</p>
                    <p class="name desc">{{costIndicatorStructures}}</p>
                </div>
                <div class="add_table">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:188px">项目</th>
                                <th style="width:120px">单方造价</th>
                                <th style="width:130px">
                                    <tr>
                                        <td>基准数据（M)</td>
                                    </tr>
                                    <tr>
                                        <td>{{costIndicatorYear}}</td>
                                    </tr>
                                </th>
                                <th style="width:120px">造价指数（P/M）</th>
                                <th style="width:150px">备注</th>
                                <th style="width:90px">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in mianList" :key="index">
                                <th>{{item.projectName}}</th>
                                <td>
                                    <el-input v-if="item.projectName!='工程造价'" v-model="mianList[index].unilateralCost" :value="item.unilateralCost"></el-input>
                                    <span v-else>&nbsp;&nbsp;&nbsp; {{unilateralCost}}</span>

                                </td>
                                <td>
                                    <el-input  v-if="item.projectName!='工程造价'" v-model="mianList[index].standardData" :value="item.standardData"></el-input>
                                    <span v-else>&nbsp;&nbsp;&nbsp; {{standardData}}</span>
                                </td>
                                <td align="center" >{{item.projectName=='工程造价' ? '-' : numFilter(mianList[index].unilateralCost/mianList[index].standardData)}}</td>
                                <td><el-input v-model="mianList[index].note" :value="item.note"></el-input></td>
                                <td style="text-align: center;">
                                    <el-button type="text" @click="saveData(mianList[index],item.projectName)">保存</el-button>
                                </td>
                            </tr>
                         
                        </tbody>
                    </table>
                    <table style="border:none">
                            <tbody>
                            <tr style="border-bottom: 1px solid #EBEBEB;border-right: 1px solid #EBEBEB;">
                                <th class="main_price">主要材料费</th>
                                <td class="main_price_name">
                                    <tr v-for="(item,index) in otherList" :key="index">
                                        <th><el-input v-model="otherList[index].projectName" :value="item.projectName"></el-input></th>
                                        <td><el-input v-model="otherList[index].unilateralCost" :value="item.unilateralCost"></el-input></td>
                                        <td><el-input v-model="otherList[index].standardData" :value="item.standardData"></el-input></td>
                                        <td align="center" >{{numFilter(otherList[index].unilateralCost/otherList[index].standardData)}}</td>
                                        <td><el-input v-model="otherList[index].note" :value="item.note"></el-input></td>
                                        <td style="text-align: center;">
                                            <el-button type="text" @click="saveData(otherList[index],item.projectName)">保存</el-button>
                                            <el-button type="text" v-if="otherList[index].id!=''" class="delete" @click="deleteData(otherList[index])">删除</el-button>
                                        </td>
                                    </tr>
                                   
                                    <tr class="add_new">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="add_btn" @click="addCustomCoefficient">+新建材料费</td>
                                        <td></td>
                                    </tr>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <el-button class="search_btn" type="primary" size="small" @click="next(2)">确定</el-button>
                <el-button class="search_btn back" size="small" @click="back(false)">返回</el-button>
                
            </div>
            <div class="add_first" v-show="isThird">
                <div class="step_box">
                    <div class="step_item one active">
                        <div class="step_icon el-icon-circle-check"></div>
                        <div class="step_name">基本信息</div>
                    </div>
                    <div class="line first active"></div>
                    <div class="step_item two active">
                        <div class="step_icon el-icon-circle-check"></div>
                        <div class="step_name">详细信息</div>
                    </div>
                    <div class="line second active"></div>
                    <div class="step_item three active">
                        <div class="step_num">3</div>
                        <div class="step_name">完成新增</div>
                    </div>
                </div>
                <div class="success">
                    <div class="success_icon el-icon-success"></div>
                    <p>新增标准成功</p>
                </div>
                <div class="lasttable">
                    <el-table ref="table" :data="ctableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
                        <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
                        <el-table-column prop="city" label="区域" align="left" width="120"></el-table-column>
                        <el-table-column prop="year" label="年度"  align="left"></el-table-column>
                        <el-table-column prop="houseType" label="房屋类型"  align="left"></el-table-column>
                        <el-table-column prop="model" label="建筑结构"  align="right">
                            <template slot-scope="scope">
                                <span>{{scope.row.highrise ? '高层' : '' }}{{ scope.row.multilayer ? '多层' : '' }}{{scope.row.smallhighrise ? '小高层' : '' }}</span>    
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="单方造价指标：元/m²"  align="right">
                            <template slot-scope="scope">
                                <span>{{scope.row.highrise ? scope.row.highrise : '' }}{{ scope.row.multilayer ? scope.row.multilayer : '' }}{{scope.row.smallhighrise ? scope.row.smallhighrise : '' }}</span>    
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                
            
                <el-button class="search_btn  " type="primary" size="small" @click="back(true)">前往查看</el-button>
                <el-button class="search_btn back" size="small" @click="next(0)">继续新增</el-button>
                
            </div>
        </div>
    </div>

</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
export default {
    props: {
        
    },
    data() {
        return {
            isFirst: true,
            isSecond: false,
            isThird: false,
            costIndicatorDictData: {},
            costIndicatorYear: '',
            costIndicatorStructures: '',
            costIndicatorHouseTypes: '',
            relationId: '',
            mianList: [],
            otherList: [],
            ctableData: [],
            unilateralCost: 0,
            standardData: 0,
            endData: []
        }
    },
    created(){
        this.getCostIndicatorDict();
        
    },
    mounted() {
    },
    methods:{
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        // 截取当前数据到小数点后两位
        numFilter(value) {
            const realVal = parseFloat(value).toFixed(2);
            if(parseFloat(realVal).toString() == "NaN" || parseFloat(realVal).toString() == "Infinity"){
                return '';
            }else{
                return realVal;
            }
        },
        getCostIndicatorDict(){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getCostIndicatorDict',
                 
                params:{
                    userId:getLocalStore('userinfo').id,
                    userSource: getLocalStore("userinfo").userSource,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                }

            }).then((res) => {
                this.costIndicatorDictData = res.data.data;
                this.relationId = res.data.data.relationId;
            })
        },
        //获取表格数据
        getCustomCoefficient(){
            this.mianList = []
            this.otherList = []
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getCustomCoefficient',                 
                data:{
                    buildStructure: this.costIndicatorStructures,
                    costExponent: "",
                    houseType: this.costIndicatorHouseTypes,
                    id: "",
                    materialFlag: "",
                    note: "",
                    projectName: "",
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                    standardData: "",
                    unilateralCost: "",
                    year: this.costIndicatorYear
                }

            }).then((res) => {
                this.endData = res.data.data;
                if(res.data.data.length==0){
                    this.mianList = [
                        {
                            projectName:'工程造价',
                            unilateralCost:'',
                            standardData:'',
                            costExponent:'',
                            note:'',
                            materialFlag:1,
                            id: ''
                        },
                        {
                            projectName:'人工费',
                            unilateralCost:'',
                            standardData:'',
                            costExponent:'',
                            note:'',
                            materialFlag:1,
                            id: ''
                        }
                    ]
                    this.otherList = [
                        {
                            projectName:'钢材',
                            unilateralCost:'',
                            standardData:'',
                            costExponent:'',
                            note:'',
                            materialFlag:0,
                            id: ''
                        },
                        {
                            projectName:'商品混凝土',
                            unilateralCost:'',
                            standardData:'',
                            costExponent:'',
                            note:'',
                            materialFlag:0,
                            id: ''
                        }  
                    ]
                }else{
                    //数据分割成两部分--------
                    res.data.data.forEach(e => {
                        if(e.materialFlag==0){
                            this.otherList.push(e)
                        }else{
                            this.mianList.push(e)
                        }
                    });
                   
                    if(this.mianList.length==0){
                        this.mianList = [
                            {
                                projectName:'工程造价',
                                unilateralCost:'',
                                standardData:'',
                                costExponent:'',
                                note:'',
                                materialFlag:1,
                                id: ''
                            },
                            {
                                projectName:'人工费',
                                unilateralCost:'',
                                standardData:'',
                                costExponent:'',
                                note:'',
                                materialFlag:1,
                                id: ''
                            }
                        ]
                    }else if(this.mianList.length==1){
                        //是否有人工费
                        let item = {
                            projectName:'',
                            unilateralCost:'',
                            standardData:'',
                            costExponent:'',
                            note:'',
                            materialFlag:1,
                            id: ''
                        }
                        if(this.mianList[0].projectName=="人工费"){
                           item.projectName = '工程造价'
                           this.mianList.unshift(item);

                           this.unilateralCost = this.mianList[1].unilateralCost;
                           this.standardData = this.mianList[1].standardData;
                        }else{
                            item.projectName = '人工费'
                            this.mianList.push(item)
                        }
                        
                    }else{
                        this.mianList.forEach(e => {
                            if(e.projectName!="工程造价"){
                                this.unilateralCost = e.unilateralCost;
                                this.standardData = e.standardData;
                            }
                        });
                    }
                  
                    this.otherList.forEach(e => {
                        this.unilateralCost += e.unilateralCost;
                        this.standardData += e.standardData;
                    });
                   
                }

            })
        },
        selectYearChange(val){
        },  
        selectHouseChange(val){

        },
        selectStructuresChange(val){
        },
        addCustomCoefficient(){
            this.getReport(2,'coefficientCalculation','addMaterial');
            let newData = {
                projectName:'',
                unilateralCost:'',
                standardData:'',
                costExponent:'',
                note:'',
                materialFlag:0,
                isAdd: true,
                id: '',
            }  
            this.otherList.push(newData)
        },
        //保存表单数据
        saveData(res,name){
            this.getReport(2,'coefficientCalculation','saveMaterial');
            let sdata = res.standardData;
            let udata = res.unilateralCost;
            if(name=="工程造价"){
                sdata = this.standardData;
                udata = this.unilateralCost;
            }else{
                sdata = res.standardData;
                udata = res.unilateralCost;
            }
            if(sdata!=''&&udata!=''){
                this.axios.request({
                    loading: true,
                    method: "post", 
                    url: '/dms/calculate/updateCustomCoefficient',
                     
                    data:{
                        buildStructure: this.costIndicatorStructures,
                        costExponent: res.costExponent,
                        houseType: this.costIndicatorHouseTypes,
                        id: res.id,
                        materialFlag: res.materialFlag,
                        note: res.note,
                        projectName: res.projectName,
                        orderNum: getLocalStore("orderNum"),
                        reportVersion: getLocalStore("reportVersion"),
                        standardData: sdata,
                        unilateralCost: udata,
                        year: this.costIndicatorYear
                    }

                }).then((res) => {
                    if(res.data.status=="success"){
                        this.$message.success('保存成功！')
                        this.unilateralCost = 0;
                        this.standardData = 0;
                        this.endData = res.data.data;
                        let otherList = [];
                        let mianList = [];
            
                        res.data.data.forEach(e => {
                            if(e.projectName!="工程造价"){
                                this.unilateralCost += e.unilateralCost;
                                this.standardData += e.standardData;
                            }
                            
                            if(e.materialFlag==0){
                                otherList.push(e)
                            }else{
                                mianList.push(e)
                            }
                        });

                        this.mianList.forEach(e => {
                            mianList.forEach(item => {
                                if(e.projectName==item.projectName){
                                    e.id = item.id
                                }
                            });
                        });
                        this.otherList.forEach(e => {
                            otherList.forEach(item => {
                                if(e.projectName==item.projectName){
                                    e.id = item.id
                                }
                            });
                        });
                
                    }else{
                        if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    }
                
                
                })
            }else{
                this.$message.error('数据请填写完整！')
            }
            

        },
        deleteData(res){
            this.getReport(2,'coefficientCalculation','deleteMaterial');
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                 this.axios.request({
                    loading: true,
                    method: "post", 
                    url: '/dms/calculate/deleteCustomCoefficient',
                     
                    data:{
                        buildStructure: this.costIndicatorStructures,
                        costExponent: res.costExponent,
                        houseType: this.costIndicatorHouseTypes,
                        id: res.id,
                        materialFlag: res.materialFlag,
                        note: res.note,
                        projectName: res.projectName,
                        orderNum: getLocalStore("orderNum"),
                        reportVersion: getLocalStore("reportVersion"),
                        standardData: res.standardData,
                        unilateralCost: res.unilateralCost,
                        year: this.costIndicatorYear
                    }

                }).then((res) => {
                    let mianList = [];
                    this.otherList = [];
                    this.unilateralCost = 0;
                    this.standardData = 0;
                    this.endData = res.data.data;
                    res.data.data.forEach(e => {
                         if(e.projectName!="工程造价"){
                            this.unilateralCost += e.unilateralCost;
                            this.standardData += e.standardData;
                        }
                        if(e.materialFlag==0){
                            this.otherList.push(e)
                        }else{
                            mianList.push(e)
                        }
                    });
                    // if(mianList.length>0){
                    //     this.mianList = mianList
                    // }
                    
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
           
        },
        next(index){
            if(index==1){   
                this.getReport(2,'coefficientCalculation','basicConfirm');
                if(this.costIndicatorYear!=''&&this.costIndicatorStructures!=''&&this.costIndicatorHouseTypes!=''){
                    this.isFirst = false;
                    this.isSecond = true;
                    this.getCustomCoefficient();
                }else{
                    this.$message.error('选项不能为空！')
                }
            }else if(index==2){   
                this.getReport(2,'coefficientCalculation','detailedConfirm');

                let nextFlag = true;
                if(this.endData.length<4){
                    nextFlag = false;
                }else{
                    let nameArr = [];
                    this.endData.forEach(e => {
                        nameArr.push(e.projectName)
                    })
                    if(nameArr.indexOf('工程造价')!=-1){
                        nextFlag = true;
                        if(nameArr.indexOf('人工费')!=-1){
                            nextFlag = true;
                            if(nameArr.indexOf('钢材')!=-1){
                                nextFlag = true;
                                if(nameArr.indexOf('商品混凝土')!=-1 || nameArr.indexOf('砼')!=-1){
                                    nextFlag = true;
                                }else{
                                    nextFlag = false;
                                }
                            }else{
                                nextFlag = false;
                            }
                        }else{
                            nextFlag = false;
                        }
                    }else{
                        nextFlag = false;
                    }
                    
                }
                if(nextFlag){
                    this.calculateResult();
                }else{
                    this.$message.error('基础数据未填完整！')
                }


                
            }else{
                this.getReport(2,'coefficientCalculation','addContinue');

                this.unilateralCost = 0;
                this.standardData = 0;
                this.costIndicatorYear = '';
                this.costIndicatorStructures = ''
                this.costIndicatorHouseTypes = ''
                this.isFirst = true;
                this.isSecond = false;
                this.isThird = false;
            }
        },
        calculateResult(){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/calculateResult',
                 
                data:{
                    buildStructure: this.costIndicatorStructures,
                    houseType: this.costIndicatorHouseTypes,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                    userId: getLocalStore('userinfo').id,
                    year: this.costIndicatorYear
                }

            }).then((res) => {
              if(res.data.status=="success"){
                this.ctableData.push(res.data.data.data);
                this.isFirst = false;
                this.isSecond = false;
                this.isThird = true;
              }else{
                if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                }else{
                    this.$message.error(res.data.msg);
                }
              }
                
            })
        },
        //返回列表
        back(flag){
            if(flag){
                this.getReport(2,'coefficientCalculation','toSee');
                this.$emit("listenAddEvent",true)
            }else{
                this.getReport(2,'coefficientCalculation','backList');
                this.$emit("listenAddEvent",true)
            }
            
        }
      
    }
}
</script>

<style scoped  lang="less">
.delete{
    color: #D40000 !important;
}
.add_box{
    width: 100%;
    min-height: 500px;
    padding-bottom: 80px;
    border-top: 1px solid #D8D8D8;
    padding-top: 100px;
    box-sizing: border-box;
    .add_title{
        font-size: 16px;
        font-weight: 500;
        color: #555555;
        line-height: 22px;
    }
    .step_box{
        width: 612px;
        height: 60px;
        margin: 0 auto;
        position: relative;
        .step_item{
            position: absolute;
            width: 56px;
            height: 60px;
            top: 0;               
            
            .step_num{
                width: 24px;
                height: 24px;
                border-radius: 24px;
                background: #C8C9CC;
                color: #ffffff;
                line-height: 24px;
                text-align: center;
                margin: 0 auto;
                margin-bottom: 8px;
            }
            .step_icon{
                width: 100%;
                height: 24px;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 8px;
            }
            .step_icon::before{
                color: #185BCE;
                font-size: 24px;
                font-weight: 600;
            }
            .step_name{
                font-size: 14px;
                font-weight: 500;
                color: #969799;
            }
            
        }
        .active{
            .step_num{
                background: #185BCE;
            }
            .step_name{
                color: #323233;
                font-weight: 600;
            }
        }
        .line{
            width: 240px;
            height: 2px;
            background: #F2F3F5;
            position: absolute;
            top: 12px; 
        }
        .line.active{
            background: #185BCE;
        }
        .first{
            left: 48px;
        }
        .second{
            left: 325px;
        }
        .one{
            left: 0;
        }
        .two{
            left: 280px;
        }
        .three{
            right: 0;
        }
        
    }
    .feed_form{
        width: 350px;
        height: 240px;
        margin: 0 auto;
        overflow: hidden;
        padding-top: 40px;
        box-sizing: border-box;
        .feed_left{
            width: 110px;
            float: left;
            text-align: right;
            .feed_question{
                height: 32px;
                line-height: 32px;
                margin-bottom: 16px;
                >i{
                    color: #D40000;
                }
            }
            .feed_proposal{
                padding-top: 6px;
                height: 74.4px;
                box-sizing: border-box;
                margin-bottom: 16px;
            }
        }
        .feed_right{
            width: 214px;
            float: right;
            .value{
                height: 32px;
                line-height: 32px;
                margin-bottom: 16px;
            }
        }
        .el-select{
            height: 32px;
            margin-bottom: 16px;
            /deep/.el-input__icon{
                line-height: 32px !important;
            }
        }
        /deep/.el-input__inner{
            width: 214px;
            height: 32px !important;
            line-height: 32px !important;
        }
    }
    .search_btn{
        position: absolute;
        left: 463px;
        bottom: 24px;
    }
    .back{
        left: 371px;
    }
    .left{
        left: 530px;
    }
    .right{
        left: 610px;
    }
    .edit_title{
        width: 100%;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        padding-left: 24px;
        box-sizing: border-box;
        .name{
            float: left;
            font-size: 14px;
            color: #666666;
        }
        .desc{
            color: #333333;
            margin-right: 16px;
            font-weight: 600;
        }
    }
    .add_table{
        width: 100%;
        height: 356px;
        overflow-y: scroll;
        margin-top: 16px;
        padding: 0 24px;
        box-sizing: border-box;
        table{
            border-spacing : 0;
            border-collapse:collapse;
            border:1px solid #EBEBEB;
            border-bottom: none;
            thead{
                height: 72px;
                tr{
                    th{
                        height: 72px;
                        border-top:1px solid #EBEBEB;
                        border-left:1px solid #EBEBEB;
                        td{
                            border-top:1px solid #EBEBEB;
                            border-left:none;
                            width:130px

                        }
                    }
                }
            }
            th{
                border-top:1px solid #EBEBEB;
                border-left:1px solid #EBEBEB;
                height: 35px;
                background: #F6F6F6;
            }
            td{
                border-top:1px solid #EBEBEB;
                border-left:1px solid #EBEBEB;
                height: 35px;
            }
            .main_price{
                width: 84px;
                height: auto;
                
            }
            .main_price_name{
                height: 100%;
                
                th{
                    width: 103px;
                    border-left: none;
                    border-top: none;
                    border-bottom: 1px solid #EBEBEB;
                }
                td{
                    border-top: none;
                    border-bottom: 1px solid #EBEBEB;
                }
                td:nth-child(2){
                    width: 120px;
                }
                td:nth-child(3){
                    width: 130px;
                }
                td:nth-child(4){
                    width: 120px;
                }
                td:nth-child(5){
                    width: 150px;
                }
                td:nth-child(6){
                    width: 90px;
                }
                .add_new{
                    width: 1062px;
                    height: 35px;
                    border-top: 1px solid #EBEBEB;
                    td{
                        border: none;
                    }
                    .add_btn{
                        color: #185BCE;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.success{
    text-align: center;
    width: 100%;
    height: 100px;
    margin-top: 40px;
    margin-bottom: 32px;
    .success_icon{
        color: #2DA641;
        font-size: 61px;
    }
    >p{
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-top: 14px;
    }
}
.lasttable{
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
}
</style>