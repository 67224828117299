<template>
    <!--无效效土地信息-->
    <div class="base-container" >
        <div class="main_box">
            <div class="input_box">
            
                <div class="right">
                    <p @click="changeType">转为有效土地信息</p>
                    <i class="el-icon-setting" @click="getMoreCol"></i>
                </div>
                <div class="col_more" v-show="isColMore">
                    <p class="title">编辑列</p>
                    <div class="reset" @click="reset">重置</div>
                    <div class="select_all" @click="selectAll">全选</div>
                    <div class="select_scroll">
                        <el-table
                            ref="multipleTable"
                            :data="tagData"
                            :show-header="false"
                            @selection-change="handleSelectionChangeTag">
                            <el-table-column
                                type="selection"
                                width="30">
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">{{ scope.row.name }}</template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-button class="confirm" type="primary" size="small" @click="confirm" >确定</el-button>
                </div>
            </div>
            <div class="table-box">
                <el-table ref="table" height="300" style="width: 100%" :data="invalidTableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"  @selection-change="handleSelectionChange">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column label="区域" width="140" :show-overflow-tooltip="true">
                         <template slot-scope="scope">
                            <span>{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="projectName" label="项目名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="projectAddress" label="项目位置" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="areaSize" label="土地面积(公顷)" width="120" align="right"></el-table-column>
                    <el-table-column label="约定容积率上下限" align="right" width="140">
                        <template slot-scope="scope">
                            <span>{{ scope.row.plotRatioDown }}-{{ scope.row.plotRatioUp }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="landUsage" label="土地用途" :show-overflow-tooltip="true" width="120"></el-table-column>
                    <el-table-column prop="landHolder" label="土地使用权人" width="160" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="contractSigningDate" label="合同签订日期" :show-overflow-tooltip="true" width="130"></el-table-column>
                    <el-table-column prop="supervisionNumber" label="电子监管号" :show-overflow-tooltip="true" v-if="idArr.includes('dzjg')" width="120"></el-table-column>
                    <el-table-column prop="landSource" label="土地来源" :show-overflow-tooltip="true" v-if="idArr.includes('tdly')" width="120"></el-table-column>
                    <el-table-column prop="landSupplyMethod" label="供地方式" :show-overflow-tooltip="true" v-if="idArr.includes('gdfs')" width="100"></el-table-column>
                    <el-table-column prop="salePrice" label="成交价格（万元）" :show-overflow-tooltip="true" v-if="idArr.includes('cjjg')" width="140"></el-table-column>
                    <el-table-column prop="landLevel" label="土地级别" :show-overflow-tooltip="true" v-if="idArr.includes('tdjb')" width="100"></el-table-column>
                    <el-table-column prop="industryCategory" label="行业" :show-overflow-tooltip="true" v-if="idArr.includes('hy')" width="120"></el-table-column>
                    <el-table-column prop="authority" label="批准单位" :show-overflow-tooltip="true" v-if="idArr.includes('pzdw')" width="120"></el-table-column>
                    <el-table-column prop="realEndTime" label="实际竣工时间" :show-overflow-tooltip="true" v-if="idArr.includes('sjjg')" width="120"></el-table-column>
                    <el-table-column prop="realStartTime" label="实际开工时间" :show-overflow-tooltip="true" v-if="idArr.includes('sjkg')" width="120"></el-table-column>
                    <el-table-column prop="arrangeEndTime" label="约定竣工时间" :show-overflow-tooltip="true" v-if="idArr.includes('ydjg')" width="120"></el-table-column>
                    <el-table-column prop="arrangeStartTime" label="约定开工时间" :show-overflow-tooltip="true" v-if="idArr.includes('ydkg')" width="120"></el-table-column>
                    <el-table-column prop="arrangeDeliveryDime" label="约定交地时间" :show-overflow-tooltip="true" v-if="idArr.includes('ydjd')" width="120"></el-table-column>
                   
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            // console.log(e.target)
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};

export default {
    components: {
    },
    props: {
        invalidTableData: [],
    },
    data() {
        return {
            multipleTable:[],
            isColMore: false,
            idArr: [],
            idPreArr: [],
            landIds: [],
            tagData: [
                {
                    name:'电子监管号',
                    id:'dzjg'
                },
                {
                    name:'土地来源',
                    id:'tdly'
                },
                {
                    name:'供地方式',
                    id:'gdfs'
                },
                {
                    name:'成交价格',
                    id:'cjjg'
                },
                {
                    name:'土地级别',
                    id:'tdjb'
                },
                {
                    name:'行业',
                    id:'hy'
                },
                {
                    name:'批准单位',
                    id:'pzdw'
                },
                {
                    name:'实际竣工时间',
                    id:'sjjg'
                },
                {
                    name:'实际开工时间',
                    id:'sjkg'
                },
                {
                    name:'约定竣工时间',
                    id:'ydjg'
                },
                {
                    name:'约定开工时间',
                    id:'ydkg'
                },
                {
                    name:'约定交地时间',
                    id:'ydjd'
                },
            ]
        }
    },
    created() {
        
    },
    mounted() {
    },
    directives: {clickoutside},
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //展示更多筛选条件
        handleSelectionChange(val){
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.urlAbbr)
            });
            this.landIds = arr;
        },
        handleSelectionChangeTag(val){
            this.getReport(2,'invalid','colEdit');
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.id)
            });
            this.idPreArr = arr;

        },
        getMoreCol(){
            this.isColMore = !this.isColMore;
        },
        reset(){
            this.$refs.multipleTable.clearSelection();
            this.getReport(2,'invalid','editReset');
        },
        selectAll(){
            this.$refs.multipleTable.toggleAllSelection();
            this.getReport(2,'invalid','editAll');
        },
        confirm(){
            this.idArr = this.idPreArr;
            this.getReport(2,'invalid','editConfirm');
        },
        changeType(){
            this.$emit("listenToChildEvent",this.landIds);
            this.getReport(2,'invalid','changeValid');
        }


      
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 4px;
}


.input_box{
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    >p{
        float: left;
        line-height: 32px;
    }
    .el-input{
        float: left;
        width: 170px;
        margin-right: 10px;
    }
    .el-select{
        float: left;
        height: 32px;
        width: 160px;
        margin-right: 6px;
    }
    .el-select{
        height: 32px;
        margin-bottom: 12px;
        /deep/.el-input__icon{
            line-height: 32px !important;
        }
    }
    /deep/.el-input__inner{
        width: 160px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-button{
        float: left;
        margin-left: 11px;
    }
    .push_chart{
        margin-left: 0 !important;
    }
    .more_search{
        color: #185BCE;
        cursor: pointer;
        margin-right: 18px;
    }
    .right{
        float: right;
        height: 100%;
        line-height: 32px;
        color: #185BCE;
        >p{
            float: left;
            margin-right: 16px;
            cursor: pointer;
        }
        >i{
            cursor: pointer;
        }
    }
    .col_more{
        width: 214px;
        height: 300px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
        position: absolute;
        top: 104px;
        right: 5px;
        box-sizing: border-box;
        padding: 14px 0;
        z-index: 99;
        .reset{
            position: absolute;
            top: 17px;
            right: 17px;
            width: 28px;
            height: 20px;
            font-size: 14px;
            color: #185BCE;
            cursor: pointer;
        }
        .select_all{
            position: absolute;
            top: 17px;
            right: 55px;
            width: 28px;
            height: 20px;
            font-size: 14px;
            color: #185BCE;
            cursor: pointer;
        }
        .select_scroll{
            width: 100%;
            height: 200px;
            overflow-y: scroll;
            border-bottom: 1px solid #EBEEF5;
        }
        .confirm{
            position: absolute;
            bottom: 5px;
            right: 10px;
        }
        /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
            border: none;
            cursor: pointer;
        }
        .el-table::before {
            height: 0;
        }
        .title{
            font-size: 16px;
            color: #323233;
            margin-bottom: 20px;
            padding-left: 11px;
            font-weight: 600;
        }
        
    }
}
.table-box{
    /deep/.is-scrolling-left,/deep/.is-scrolling-right,/deep/.is-scrolling-middle{
        height: 265px !important;
    }
}

</style>