<template>
<div>
    <div class="card">
        <div class="nav_box">
            <div class="nav1 navbg_blue">土地信息</div>
            <div class="nav2 navbg_gray">房价信息</div>
            <div class="nav3 navbg_gray">材料价格信息</div>
            <div class="nav4 navbg_gray2">工程造价指标</div>
        </div>
        <div class="tab_box">
            <div class="tab-item" :class="{'selected':activeName == 'yx'}" @click="titleTab('yx')">有效土地信息</div>
            <div class="tab-item" :class="{'selected':activeName == 'wx'}" @click="titleTab('wx')">无效土地信息</div>
        </div>
        <div class="module-container">
            <div class="main_box">
                <div class="input_box" v-clickoutside="handleClose">
                    <p>项目名称：</p>
                    <el-input class="search-content" size="small" v-model="projectName" placeholder="点击输入项目名称" ></el-input>
                    <p>电子监管号：</p>
                    <el-input class="search-content" size="small" v-model="supervisionNumber" placeholder="点击输入电子监管号" ></el-input>
                    <p>土地用途：</p>
                    <el-select @change="selectChange" v-model="landUsageSuperior" placeholder="请选择">
                        <el-option
                        v-for="(item,key) in landUsageData.landUage"
                        :key="key"
                        :label="key"
                        :value="key">
                        </el-option>
                    </el-select>
                    <el-select v-model="landUsage" placeholder="请选择">
                        <el-option
                        v-for="item in landUsageList"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                    <p class="more_search" @click="getMoreSelect">更多查询条件</p>
                    <el-button class="search_btn" type="primary" size="small" @click="searchData(true)">查询</el-button>
                    <el-button class="search_btn" size="small" @click="reset">重置</el-button>
                    <div class="select_more" v-show="isMoreSelect">
                        <p class="title">更多查询条件</p>
                        <div class="input">
                            <div class="input_item">
                                <div class="item_title">项目位置</div>
                                <el-input class="search-content" size="small" placeholder="点击输入项目位置" v-model="projectAddress" ></el-input>
                            </div>
                            <div class="input_item">
                                <div class="item_title">供地方式</div>
                                <el-select v-model="landSupplyMethod" placeholder="请选择">
                                    <el-option
                                    v-for="item in landUsageData.supplyMethod"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="input_item">
                                <div class="item_title">容积率</div>
                                <el-input class="search-content small_input" type="number" size="small" placeholder="最低" v-model="plotRatioUp"></el-input>
                                <p class="line">~</p>
                                <el-input class="search-content small_input" type="number" size="small" placeholder="最高" v-model="plotRatioDown"></el-input>
                            </div>
                            <div class="input_item">
                                <div class="item_title">成交价格(万)</div>
                                <el-input class="search-content small_input" type="number" size="small" placeholder="最低" v-model="salePriceStart"></el-input>
                                <p class="line">~</p>
                                <el-input class="search-content small_input" type="number" size="small" placeholder="最高" v-model="salePriceEnd"></el-input>
                            </div>
                            <div class="input_item">
                                <div class="item_title">土地级别</div>
                                <el-select v-model="landLevel" placeholder="请选择">
                                    <el-option
                                    v-for="item in landUsageData.landLevel"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="input_item">
                                <div class="item_title">土地使用权人</div>
                                <el-input class="search-content" size="small" placeholder="点击输入土地使用权人" v-model="landHolder"></el-input>
                            </div>
                            <div class="input_item">
                                <div class="item_title">行业</div>
                                <el-select @change="industryChange" v-model="industryParent" placeholder="请选择">
                                    <el-option
                                    v-for="(item,key) in landUsageData.industry"
                                    :key="key"
                                    :label="key"
                                    :value="key">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="input_item">
                                <div class="item_title"></div>
                                <el-select v-model="industryCategory" placeholder="请选择">
                                    <el-option
                                    v-for="item in industryCategoryList"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="input_item">
                                <div class="item_title">批准单位</div>
                                <el-input class="search-content" size="small" placeholder="点击输入批准单位" v-model="authority"></el-input>
                            </div>
                            <div class="input_item">
                                <div class="item_title">土地面积(公顷)</div>
                                <el-input class="search-content small_input" type="number" size="small" placeholder="最低" v-model="areaSizeStart"></el-input>
                                <p class="line">~</p>
                                <el-input class="search-content small_input" type="number" size="small" placeholder="最高" v-model="areaSizeEnd"></el-input>
                            </div>
                            <div class="input_item">
                                <div class="item_title">合同签订日期</div>
                            </div>
                            <div class="input_item">
                                <el-date-picker class="date_select"
                                    v-model="contractSigningDate"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="input_item">
                                <div class="item_title">实际竣工时间</div>
                            </div>
                            <div class="input_item">
                                <el-date-picker class="date_select"
                                    v-model="realEndTime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="input_item">
                                <div class="item_title">实际开工时间</div>
                            </div>
                            <div class="input_item">
                                <el-date-picker class="date_select"
                                    v-model="realStartTime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="input_item">
                                <div class="item_title">约定竣工时间</div>
                            </div>
                            <div class="input_item">
                                <el-date-picker class="date_select"
                                    v-model="arrangeEndTime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="input_item">
                                <div class="item_title">约定开工时间</div>
                            </div>
                            <div class="input_item">
                                <el-date-picker class="date_select"
                                    v-model="arrangeStartTime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="input_item">
                                <div class="item_title">约定交地时间</div>
                            </div>
                            <div class="input_item">
                                <el-date-picker class="date_select"
                                    v-model="arrangeDeliveryDime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <effective v-if="activeName == 'yx'" :validTableData="validTableData" v-on:listenToChildEvent="getLandIds"></effective>
                <invalid v-if="activeName == 'wx'" :invalidTableData="invalidTableData" v-on:listenToChildEvent="getWxLandIds"></invalid>
                <div class="page-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        layout="prev,pager,next,total,sizes,jumper"
                        :page-sizes="[10, 20, 50, 100, 1000, 2000]"
                        :total="total"
                        :current-page="pageNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
    <div class="next_box">
        <!-- <div class="pop_btn">上一步</div> -->
        <div class="next_btn" @click="toNext">下一步</div>
    </div>
</div>

</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

import effective from '@/views/industryAnalysis/effective.vue'
import invalid from '@/views/industryAnalysis/invalid.vue'
const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            // console.log(e.target)
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};
export default {
    components:{
        effective:effective,
        invalid:invalid,
    },
    data() {
        return {
            validTableData:[],
            invalidTableData:[],
            activeName: 'yx',
            landUsageData: {},
            projectName:'',
            supervisionNumber:'',
            isMoreSelect: false,
            landUsageList: [],
            landUsageSuperior: '',
            landUsage: '',
            projectAddress: '',
            landSupplyMethod: '',
            plotRatioUp: '',
            plotRatioDown: '',
            salePriceStart: '',
            salePriceEnd: '',
            landLevel: '',
            landHolder: '',
            industryParent: '',
            industryCategory: '',
            industryCategoryList: [],
            authority: '',
            areaSizeStart: '',
            areaSizeEnd: '',
            contractSigningDate: [],
            realStartTime: [],
            realEndTime: [],
            arrangeEndTime: [],
            arrangeStartTime: [],
            arrangeDeliveryDime: [],
            pageNum: 1,
            pageSize: 10,
            landIds: [],
            total: 0,
            isSearch: false,
            relationId: '',
        }
    },
    created() {
   
    },
 
    mounted() {
        this.getLandUsage();
        this.getLandOfCustomerArea(0);
    },
    directives: {clickoutside},
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        titleTab(title){
            this.activeName = title;
            this.pageNum = 1;
            this.reset();
            if(this.activeName=='wx'){
                this.getReport(2,'landTransaction','invalidTag');
                this.getLandOfCustomerArea(1);
                this.isChart = false;
            }else{
                this.getReport(2,'landTransaction','validTag');
                this.getLandOfCustomerArea(0);
            }
        },
        //初始化有效数据
        getLandOfCustomerArea(type){
            this.isMoreSelect = false;
            let data = {
                userId: getLocalStore('userinfo').id,
                type: type,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                supervisionNumber: this.supervisionNumber,
                salePriceStart: this.salePriceStart,
                salePriceEnd: this.salePriceEnd,
                landUsageSuperior: this.landUsageSuperior,
                landUsage: this.landUsage,
                projectName: this.projectName,
                projectAddress: this.projectAddress,
                plotRatioUp: this.plotRatioUp,
                plotRatioDown: this.plotRatioDown,
                landSupplyMethod: this.landSupplyMethod,
                landLevel: this.landLevel,
                landHolder: this.landHolder,
                industryParent: this.industryParent,
                industryCategory: this.industryCategory,
                authority: this.authority,
                contractSigningDate: this.contractSigningDate,
                realEndTime: this.realEndTime,
                realStartTime: this.realStartTime,
                arrangeEndTime: this.arrangeEndTime,
                arrangeStartTime: this.arrangeStartTime,
                arrangeDeliveryDime: this.arrangeDeliveryDime,
                areaSizeStart: this.areaSizeStart,
                areaSizeEnd: this.areaSizeEnd,
                transferType: '',
                landIds: [],
                userSource:getLocalStore('userinfo').userSource,
                orderNum: getLocalStore("orderNum"),
                reportVersion: getLocalStore("reportVersion"),
            }
             this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getLandOfCustomerArea',
                 
                data:data

            }).then((res) => {

                if(type==0){
                    this.validTableData = res.data.data.page.records;
                    this.total = res.data.data.page.total;
                }else{
                    this.invalidTableData = res.data.data.page.records;
                    this.total = res.data.data.page.total;
                }
                

            })
        },
        getMoreSelect(){
            this.isMoreSelect = !this.isMoreSelect;
            if(this.activeName=='wx'){
                this.getReport(2,'invalid','moreCondition');
            }else{
                this.getReport(2,'valid','moreCondition');
            }
        },
        handleClose(e) {
            this.isMoreSelect = false;
        },
        //获取土地查询条件选项
        getLandUsage(){
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/getLandUsage',
                 
            }).then((res) => {
                this.landUsageData = res.data.data
            })
        },
        //土地用途父级change
        selectChange(val){
            this.landUsageList = this.landUsageData.landUage[val];
            this.landUsage = ''
        },
        industryChange(val){
            this.industryCategoryList = this.landUsageData.industry[val];
            this.industryCategory = ''
        },
        //查询数据
        searchData(flag){
            this.isSearch = true;
            this.isMoreSelect = false;
            let type = 0;
            if(this.activeName=='wx'){
                this.getReport(2,'invalid','searchBtn');
                type = 1;
            }else{
                this.getReport(2,'valid','searchBtn');
                type = 0;
            }
            if(flag){
                this.pageNum = 1;
            }
            let data = {
                userId: getLocalStore('userinfo').id,
                type: type,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                supervisionNumber: this.supervisionNumber,
                salePriceStart: this.salePriceStart,
                salePriceEnd: this.salePriceEnd,
                landUsageSuperior: this.landUsageSuperior,
                landUsage: this.landUsage,
                projectName: this.projectName,
                projectAddress: this.projectAddress,
                plotRatioUp: this.plotRatioUp,
                plotRatioDown: this.plotRatioDown,
                landSupplyMethod: this.landSupplyMethod,
                landLevel: this.landLevel,
                landHolder: this.landHolder,
                industryParent: this.industryParent,
                industryCategory: this.industryCategory,
                authority: this.authority,
                contractSigningDate: this.contractSigningDate,
                realEndTime: this.realEndTime,
                realStartTime: this.realStartTime,
                arrangeEndTime: this.arrangeEndTime,
                arrangeStartTime: this.arrangeStartTime,
                arrangeDeliveryDime: this.arrangeDeliveryDime,
                areaSizeStart: this.areaSizeStart,
                areaSizeEnd: this.areaSizeEnd,
                transferType: '',
                landIds: this.landIds,
                userSource:getLocalStore('userinfo').userSource,
                orderNum: getLocalStore("orderNum"),
                reportVersion: getLocalStore("reportVersion"),
            }
             this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/getLandOfCustomerArea',
                 
                data:data

            }).then((res) => {
                if(this.activeName=='yx'){
                    this.validTableData = res.data.data.page.records;
                    this.total = res.data.data.page.total;
                }else{
                    this.invalidTableData = res.data.data.page.records;
                    this.total = res.data.data.page.total;
                }
                
            })
        },
        //重置
        reset(){
                this.supervisionNumber = '';
                this.salePriceStart = '';
                this.salePriceEnd = '';
                this.landUsageSuperior = '';
                this.landUsage = '';
                this.projectName = '';
                this.projectAddress = '';
                this.plotRatioUp = '';
                this.plotRatioDown = '';
                this.landSupplyMethod = '';
                this.landLevel = '';
                this.landHolder = '';
                this.industryParent = '';
                this.industryCategory = '';
                this.authority = '';
                this.contractSigningDate = [];
                this.realEndTime = [];
                this.realStartTime = [];
                this.arrangeEndTime = [];
                this.arrangeStartTime = [];
                this.arrangeDeliveryDime = [];
                this.areaSizeStart = '';
                this.areaSizeEnd = '';
                if(this.activeName=='wx'){
                    this.getReport(2,'invalid','resetBtn');
                }else{
                    this.getReport(2,'valid','resetBtn');
                }
        },
        handleSizeChange(val){
            this.pageSize = val;
            this.pageNum = 1;
            if(this.isSearch){
                this.searchData(false)
            }else{
                if(this.activeName=='wx'){
                    this.getLandOfCustomerArea(1);
                }else{
                    this.getLandOfCustomerArea(0);
                }
            }
            
           
        },
        handleCurrentChange(val){
            this.pageNum = val;
            if(this.activeName=='wx'){
                this.getReport(2,'invalid',val);
            }else{
                this.getReport(2,'valid',val);
            }
            if(this.isSearch){
                this.searchData(false)
            }else{
                if(this.activeName=='wx'){
                    this.getLandOfCustomerArea(1);
                }else{
                    this.getLandOfCustomerArea(0);
                }
            }
            
        },
        //获取有效 子组件的id列表
        getLandIds(data){
            this.changeWx(data,1)
        },
        //获取无效 子组件的id列表
        getWxLandIds(data){
            this.wxlandIds = data;
            this.changeWx(data,0)
        },
        //查询数据
        changeWx(landIds,toType){
            this.isSearch = true;
            this.isMoreSelect = false;
            let type = 0;
            if(this.activeName=='wx'){
                type = 1;
            }else{
                type = 0;
            }
            let data = {
                userId: getLocalStore('userinfo').id,
                type: type,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                supervisionNumber: this.supervisionNumber,
                salePriceStart: this.salePriceStart,
                salePriceEnd: this.salePriceEnd,
                landUsageSuperior: this.landUsageSuperior,
                landUsage: this.landUsage,
                projectName: this.projectName,
                projectAddress: this.projectAddress,
                plotRatioUp: this.plotRatioUp,
                plotRatioDown: this.plotRatioDown,
                landSupplyMethod: this.landSupplyMethod,
                landLevel: this.landLevel,
                landHolder: this.landHolder,
                industryParent: this.industryParent,
                industryCategory: this.industryCategory,
                authority: this.authority,
                contractSigningDate: this.contractSigningDate,
                realEndTime: this.realEndTime,
                realStartTime: this.realStartTime,
                arrangeEndTime: this.arrangeEndTime,
                arrangeStartTime: this.arrangeStartTime,
                arrangeDeliveryDime: this.arrangeDeliveryDime,
                areaSizeStart: this.areaSizeStart,
                areaSizeEnd: this.areaSizeEnd,
                transferType: toType,
                landIds: landIds,
                orderNum: getLocalStore("orderNum"),
                reportVersion: getLocalStore("reportVersion"),
            }
             this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/landTransfer',
                 
                data:data

            }).then((res) => {
                this.invalidCount = res.data.data.num.invalidCount;
                this.validCount = res.data.data.num.validCount;

                if(this.activeName=='yx'){
                    this.validTableData = res.data.data.page.records;
                    this.total = res.data.data.num.validCount;
                }else{
                    this.invalidTableData = res.data.data.page.records;
                    this.total = res.data.data.num.invalidCount;
                }
                
            })
        },
        //下一步
        toNext(){
            if(this.total==0){
                this.$message.error('当前区域暂无土地交易数据，无法进行测算！');
                return false;
            }
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/calculate/saveLandToUser',
                params:{
                    userId:getLocalStore('userinfo').id,
                    userSource: getLocalStore("userinfo").userSource,
                    orderNum: getLocalStore("orderNum"),
                    reportVersion: getLocalStore("reportVersion"),
                }

            }).then((res) => {
                this.$message.success(res.data.msg);
                this.$emit("listenNext",'house');
            })
            
        }
   
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.module-container{
    height: 100%;
}
.card{
    width: 100%;
    height: 582px;
    background-color: #fff;
    .nav_box{
        width: 100%;
        height: 64px;
        position: relative;
        div{
            position: absolute;
            text-align: center;
            width: 169px;
            height: 32px;
            line-height: 32px;
            top: 16px;
        }
        .nav1{
            left: 278px;
        }
        .nav2{
            left: 441px;
        }
        .nav3{
            left: 603px;
        }
        .nav4{
            left: 766px;
            width: 156px;
        }
        .navbg_blue{
            background: url('../../assets/img/topnav.png');
            color: #fff;
        }
        .navbg_blue2{
            background: url('../../assets/img/topnav4.png');
            color: #fff;
        }
        .navbg_blue3{
            background: url('../../assets/img/topnav5.png');
            color: #fff;
        }
        .navbg_gray{
            background: url('../../assets/img/topnav2.png');
        }
        .navbg_gray2{
            background: url('../../assets/img/topnav3.png');
            
        }
    }
    .tab_box{
        width: 100%;
        height: 32px;
        padding-left: 24px;
        box-sizing: border-box;
        .tab-item{
            float: left;
            width: 104px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            text-align: center;
            border: 1px solid #185BCE;
            cursor: pointer;
            box-sizing: border-box;
        }
        
        .selected {
            background: #185BCE;
            color: #FFFFFF;
        }
    }
    .search_box{
        float: right;
        height: auto;
        padding-top: 12px;
        overflow: hidden;
        .el-input{
            float: left;
            width: 170px;
            margin-right: 10px;
        }
        /deep/.el-input__inner{
            width: 160px;
            height: 32px !important;
            line-height: 32px !important;
        }
        /deep/.el-input__icon{
            line-height: 32px !important;
        }
        .el-button{
            float: left;
            margin-right: 24px;
        }
        p{
            float: left;
            height: 32px;
            line-height: 32px;
            margin-right: 10px;
        }
        .el-select{
            float: left;
            margin-right: 24px;
        }
    }
    .main_box{
        padding: 24px;
        position: relative;
    }
    .input_box{
        width: 100%;
        height: 32px;
        margin-bottom: 16px;
        >p{
            float: left;
            line-height: 32px;
        }
        .el-input{
            float: left;
            width: 170px;
            margin-right: 10px;
        }
        .el-select{
            float: left;
            height: 32px;
            width: 160px;
            margin-right: 6px;
            margin-bottom: 12px;
            /deep/.el-input__icon{
                line-height: 32px !important;
            }
        }
        /deep/.el-input__inner{
            width: 160px;
            height: 32px !important;
            line-height: 32px !important;
        }
        .el-button{
            float: left;
            margin-left: 12px;
        }
        .push_chart{
            margin-left: 0 !important;
        }
        .more_search{
            color: #185BCE;
            cursor: pointer;
        }
        .right{
            float: right;
            height: 100%;
            line-height: 32px;
            color: #185BCE;
            >p{
                float: left;
                margin-right: 16px;
                cursor: pointer;
            }
            >i{
                cursor: pointer;
            }
        }
        .select_more{
            width: 290px;
            height: 320px;
            background: #FFFFFF;
            box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
            position: absolute;
            top: 51px;
            right: 177px;
            box-sizing: border-box;
            padding: 14px 0;
            z-index: 99;
            .title{
                font-size: 16px;
                color: #323233;
                margin-bottom: 26px;
                padding-left: 11px;
                font-weight: 600;
            }
            .input{
                width: 100%;
                height: 250px;
                padding: 0px 11px;
                box-sizing: border-box;
                overflow-y: scroll;
                .input_item{
                    overflow: hidden;
                    margin-bottom: 16px;
                    .el-input{
                        float: left;
                        width: 144px;
                        /deep/.el-input__inner{
                            padding: 0 0 0 8px;
                        }
                    }
                    .el-input.small_input{
                        width: 66px;
                        margin-right: 0;
                        /deep/.el-input__inner{
                            width: 66px;
                            padding: 0 0 0 8px;
                        }
                    }
                    .el-input__inner.date_select{
                        width: 100%;
                        /deep/.el-range__icon{
                            line-height: 25px;
                        }
                        /deep/.el-range-separator{
                            line-height: 25px;
                        }
                        /deep/.el-range__close-icon{
                            line-height: 25px;
                        }
                    }
                    .el-select{
                        float: left;
                        width: 144px;
                        margin-bottom: 0;
                        /deep/.el-input__inner{
                            padding: 0 0 0 8px;
                        }
                    }
                    .item_title{
                        float: left;
                        width: 88px;
                        min-height: 32px;
                        line-height: 32px;
                        font-weight: 400;
                        color: #646566;
                    }
                    .line{
                        float: left;
                        line-height: 32px;
                        margin: 0 10px;
                        color: #646566;
                    }
                }
            }
        }
    }
    
    /deep/.el-tabs__content{
        overflow: initial !important;
    }
    /deep/.el-tabs__header{
        border-bottom: none !important;
        
    }
    /deep/.el-tabs__nav{
        border: none !important;
    }
    /deep/.el-tabs__item{
        background: rgba(50, 50, 51, 0.06);
        border: none;
    }
    /deep/.cell{
        line-height: 22px;
    }
    /deep/.el-table__cell{
        padding: 6px 0;
    }
}
.next_box{
    overflow: hidden;
    display: flex;
    justify-content:center;
    .pop_btn{
    display: flex;
    width: 42px;
    height: 32px;
    line-height: 32px;
    margin-right: 38px;
    color: #185BCE;
    cursor: pointer;
    }
    .next_btn{
    display: flex;
    width: 80px;
    height: 32px;
    line-height: 32px;
    justify-content:center;
    color: #FFFFFF;
    background: #185BCE;
    cursor: pointer;
    }
}

</style>